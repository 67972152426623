import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpeechRecognitionService {
  insertTextAt: number;
  isListening = signal(false);
  newSpokenText = signal(false);
  noteError = signal(false);
  rawError: string;
  recognition;
  speechArray = [];
  SpeechRecognition;
  spokenText: string;

  constructor(private win: Window) {}

  startListening() {
    this.newSpokenText.set(false);
    this.noteError.set(false);
    this.recognition.start();
  }
  stopListening() {
    this.recognition.stop();
  }
  getParsedSpeech() {
    this.speechArray = [];
    return this.spokenText;
  }
  getError() {
    return `There was an error converting speech to text. Please try again. Error: ${this.rawError}`;
  }
  parseSpeech(event) {
    this.speechArray.push(
      event.results[event.results.length - 1][0].transcript
    );
    for (let i = 0; i < this.speechArray.length; i++) {
      this.speechArray[i] = this.speechArray[i].replaceAll(' comma ', ', ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' comma', ',');
      this.speechArray[i] = this.speechArray[i].replaceAll(
        ' exclamation point ',
        '! '
      );
      this.speechArray[i] = this.speechArray[i].replaceAll(
        ' exclamation point',
        '!'
      );
      this.speechArray[i] = this.speechArray[i].replaceAll(' period ', '. ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' period', '.');
      this.speechArray[i] = this.speechArray[i].replaceAll(' full stop ', '. ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' full stop', '.');
      this.speechArray[i] = this.speechArray[i].replaceAll(
        ' question mark ',
        '? '
      );
      this.speechArray[i] = this.speechArray[i].replaceAll(
        ' question mark',
        '?'
      );
      this.speechArray[i] = this.speechArray[i].replaceAll(' percent ', '% ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' percent', '%');
      this.speechArray[i] = this.speechArray[i].replaceAll(' colon ', ': ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' colon', ':');
      this.speechArray[i] = this.speechArray[i].replaceAll(' semicolon ', '; ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' semicolon', ';');
    }
    const spokenTextLetters = Array.from(this.speechArray.join(' '));
    const fullTextLetters = Array.from(this.spokenText);

    if (
      this.insertTextAt === fullTextLetters.length &&
      fullTextLetters[fullTextLetters.length - 1] !== ' '
    ) {
      fullTextLetters.push(' ');
      this.insertTextAt = fullTextLetters.length;
    }

    for (let i = spokenTextLetters.length - 1; i > -1; i--) {
      const letter = spokenTextLetters[i];
      fullTextLetters.splice(this.insertTextAt, 0, letter);
    }
    this.spokenText = fullTextLetters.join('');
  }

  voiceSetup() {
    this.SpeechRecognition =
      this.win['SpeechRecognition'] || this.win['webkitSpeechRecognition'];
    this.recognition = new this.SpeechRecognition();

    this.recognition.lang = 'en-US';

    this.recognition.onstart = () => {
      this.isListening.set(true);
    };
    this.recognition.onend = () => {
      this.isListening.set(false);
    };
    this.recognition.onerror = error => {
      this.rawError = error.error;
      this.spokenText = '';
      this.newSpokenText.set(false);
      this.noteError.set(true);
      this.isListening.set(false);
    };
    this.recognition.onresult = event => {
      this.parseSpeech(event);
      this.newSpokenText.set(true);
    };
  }
}
