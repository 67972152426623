import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { PostTradeService } from '../post-trade.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { capitalize } from 'lodash';

@Component({
  selector: 'app-review-download-dialog',
  templateUrl: './review-download-dialog.component.html',
  styleUrls: ['./review-download-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewDownloadDialogComponent {
  fileName = '';
  downloadRequested = false;
  gridTitle = capitalize(this.data.gridType);

  constructor(
    public dialogRef: MatDialogRef<ReviewDownloadDialogComponent>,
    private ptSvc: PostTradeService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  downloadGridReport() {
    const { columns, rows, query } = this.data;
    const requestQuery = query;
    const requestCols = { columns };

    if (requestQuery.take) {
      delete requestQuery.take;
    }
    if (requestQuery.skip) {
      delete requestQuery.skip;
    }

    switch (this.data.gridType) {
      case 'type':
        this.ptSvc
          .generatePostTradeReviewReport(
            this.gridTitle,
            this.fileName,
            encodeURIComponent(JSON.stringify(requestQuery)),
            encodeURIComponent(JSON.stringify(requestCols)),
            rows
          )
          .subscribe(resp => {
            if (resp['result'] === 'Report request created') {
              this.downloadRequested = true;
            }
          });
        break;

      case 'account':
        this.ptSvc
          .generatePostTradeReviewReport(
            this.gridTitle,
            this.fileName,
            encodeURIComponent(JSON.stringify(requestQuery)),
            encodeURIComponent(JSON.stringify(requestCols)),
            rows
          )
          .subscribe(resp => {
            if (resp['result'] === 'Report request created') {
              this.downloadRequested = true;
            }
          });
        break;

      default:
        break;
    }
  }
}
