import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { PostTradeService } from '../../post-trade.service';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import {
  tradeInfoKey,
  productInfoKey,
  suitabilityKey,
  accountKey,
  tradeHistoryKey,
  tradePositionKey,
  tradeApprovalKey,
  scoreClassKey,
  annuityPositionKey,
  associatedCaseKey,
  productModulesKey,
  caseHistoryKey,
} from './trade-review-detail-keys';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEmpty } from 'lodash';
import { CaseManagementService } from '../../../case-management/case-management.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { TradeHistoryDialogComponent } from './trade-history-dialog/trade-history-dialog.component';
import { TradeAttachmentsDialogComponent } from '../trade-attachments-dialog/trade-attachments-dialog.component';

@Component({
  selector: 'app-trade-review-detail',
  templateUrl: './trade-review-detail.component.html',
  styleUrls: ['./trade-review-detail.component.scss'],
})
export class TradeReviewDetailComponent implements OnInit, OnDestroy {
  @Input() tradeId;
  rights = [];
  tradeDetail;
  caseHistoryKey = caseHistoryKey;
  tradeInfoKey = tradeInfoKey;
  productInfoKey = productInfoKey;
  suitabilityKey = suitabilityKey;
  accountKey = accountKey;
  tradeHistoryKey = tradeHistoryKey;
  tradePositionKey = tradePositionKey;
  tradeApprovalKey = tradeApprovalKey;
  annuityPositionKey = annuityPositionKey;
  associatedCaseKey = associatedCaseKey;
  productModulesKey = productModulesKey;
  scoreClassKey = scoreClassKey;
  isEmpty = isEmpty;
  mutualFundRows = 5;
  tradeHistoryRows = 5;
  productRecommendations = {};
  productModules = [];
  caseHistory = [];
  caseHistoryRows = 5;
  reviewCase = false;
  caseChangesMade = false;
  caseData;

  constructor(
    private dialog: MatDialog,
    public fb: FormBuilder,
    public ptSvc: PostTradeService,
    public cms: CaseManagementService,
    public ss: SessionStorageService,
    private route: Router,
    private snacky: MatSnackBar
  ) {}

  Highcharts: typeof Highcharts = Highcharts;
  unsubscribe: Subject<any> = new Subject();
  loading = false;

  ngOnInit(): void {
    this.loading = true;
    this.rights = this.ss.get('rights');
    this.ptSvc.getPostTradeDetail(this.tradeId).subscribe(resp => {
      this.tradeDetail = resp;
      this.sortProductRecommendations(resp['Details']['ProductSelected']);
      this.loading = false;
    });

    this.cms.getRelatedCases(this.tradeId, ['TRADE']).subscribe(resp => {
      if (resp['length'] > 0) {
        Object.keys(resp).map(index => {
          this.caseHistory.push(resp[index]);
        });
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  sortProductRecommendations(productList) {
    if (productList && productList.length > 0) {
      productList.map(product => {
        if (this.productRecommendations[product.Module]) {
          this.productRecommendations[product.Module].push(product);
        } else {
          this.productModules.push(product.Module);
          this.productRecommendations[product.Module] = [product];
        }
      });
    }
  }

  returnZero() {
    return 0;
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }

  toggleApproval(approvalStatus) {
    const formData = new FormData();
    formData.append(
      'Configuration',
      JSON.stringify({
        TradeID: [this.tradeId],
        ApprovalStatus: approvalStatus,
      })
    );
    this.loading = true;
    this.ptSvc.updateTrade(formData).subscribe(() => {
      this.ptSvc.getPostTradeDetail(this.tradeId).subscribe(resp => {
        this.snacky.open(`${this.tradeId} was updated!`, 'Close', {
          duration: 4000,
        });

        this.tradeDetail = resp;
        this.loading = false;
      });
    });
  }

  downloadFactSheet() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  seeDocuments() {
    this.dialog.open(TradeAttachmentsDialogComponent, {
      data: {
        tradeId: this.tradeId,
        tradeDetail: this.tradeDetail,
        profileType: 'trade',
      },
      width: '80vw',
      maxWidth: '80vw',
    });
  }

  viewTradeApprovalHistory() {
    this.dialog.open(TradeHistoryDialogComponent, {
      data: {
        tradeDetail: this.tradeDetail,
      },
      width: '80vw',
      maxWidth: '80vw',
    });
  }

  toggleCaseReview(caseItem) {
    this.cms.getCaseManagementProfile(caseItem.CRID).subscribe(() => {
      this.caseData = caseItem;
      this.reviewCase = !this.reviewCase;
    });
  }

  toggleRows(rowProp, value) {
    if (this[rowProp] !== value) {
      this[rowProp] = value;
    } else {
      this[rowProp] = 5;
    }
  }

  changeCaseValue(value) {
    this.caseChangesMade = value;
  }
}
