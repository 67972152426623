import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-form-type-ahead',
  styleUrls: ['form-typeAhead.component.scss'],
  template: `
    <label
      class="section w-100"
      [class.mat-error]="formControl.errors && formControl.touched"
      *ngIf="props.label"
      >{{props.label}}
      <sup *ngIf="props.required">*</sup></label>
    <p *ngIf="props.prompt" class="explanation" [innerHTML]="props.prompt" [class.mat-error]="formControl.errors && formControl.touched"></p>

    <mat-form-field class="w-100" [class.mat-form-field-invalid]="formControl.errors && formControl.touched">
      <mat-label>{{ props.placeholder || props.label }}<sup *ngIf="props.required">*</sup></mat-label>
      <input
        [defaultValue]="formControl.value"
        class="w-100"
        type="text"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        [placeholder]="props.placeholder || props.label"
        [class.mat-mdc-form-field-error]="formControl.errors && formControl.touched"
      />
      <mat-hint *ngIf="formControl.errors && formControl.touched">This field is required</mat-hint>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
        >
          {{ option.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
})
export class FormlyTypeAheadComponent extends FieldType implements OnInit {
  myControl = new FormControl<any>('');
  theOptions = [];
  filteredOptions: Observable<any>;

  ngOnInit() {
    this.props.options.forEach(option => {
      this.theOptions.push(option);
    });
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        if (typeof value !== 'string') {
          this.formControl.setValue(value.value);
        }
        const name = typeof value === 'string' ? value : value?.label;
        return name ? this._filter(name as string) : this.theOptions.slice();
      })
    );
    const defaultValue = this.theOptions.find(
      option => option.value === this.formControl.value
    );
    this.myControl.setValue(defaultValue);
    if (this.props.disabled) {
      this.myControl.disable();
    }
  }

  displayFn(user): string {
    return user && user.label ? user.label : '';
  }

  private _filter(name: string) {
    const filterValue = name.toLowerCase();

    return this.theOptions.filter(option =>
      option.label.toLowerCase().includes(filterValue)
    );
  }
}
