import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SpeechRecognitionService } from '../../services/speech-recognition.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-speech-to-text',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './speech-to-text.component.html',
  styleUrl: './speech-to-text.component.scss',
})
export class SpeechToTextComponent implements OnChanges {
  @Input() typedText: string;
  @Input() cursorPosition: number;
  @Output() text = new EventEmitter();
  @Output() noteSpeechError = new EventEmitter();
  micButtonStatus = 'disabled';

  constructor(private speechService: SpeechRecognitionService) {
    this.speechService.voiceSetup();
    effect(() => {
      if (this.speechService.isListening()) {
        this.micButtonStatus = 'primary';
        this.noteSpeechError.emit(null);
      } else if (!this.speechService.isListening()) {
        this.micButtonStatus = 'disabled';
        if (this.speechService.newSpokenText()) {
          this.text.emit(this.speechService.getParsedSpeech());
        } else if (this.speechService.noteError()) {
          this.noteSpeechError.emit(this.speechService.getError());
        }
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.typedText &&
      typeof changes.typedText.currentValue === 'string'
    ) {
      this.speechService.spokenText = changes.typedText.currentValue;
    }
  }
  toggleListening(ev) {
    if (this.speechService.isListening()) {
      this.speechService.stopListening();
    } else {
      this.speechService.insertTextAt = this.cursorPosition;
      this.speechService.startListening();
    }
  }
}
