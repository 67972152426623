<div mat-dialog-header>
  <h1>{{ header }}</h1>
</div>
<mat-dialog-content #scroll class="dialog-container">
  <div class="grid-table w-100 row my-5" *ngFor="let gr of grids">
    <!-- <div class="table-header w-100" *ngIf="gr.title">{{gr.title}}</div> -->
    <table
      *ngFor="let tb of gr.tables"
      [ngClass]="{
        'col-12': gr.tables.length == 1,
        'col-6': gr.tables.length == 2,
        'col-4': gr.tables.length == 3,
        'col-3': gr.tables.length == 4
      }"
    >
      <thead>
        <ng-container *ngFor="let hd of tb.headers">
          <th *ngIf="hd !== 'Remove Me'">{{ hd }}</th>
        </ng-container>
      </thead>
      <tbody>
        <tr *ngFor="let rw of tb.data">
          <td *ngFor="let cell of rw.row" [innerHTML]="cell"></td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="row buttons col-12">
  <form class="w-75" *ngIf="!informationOnly">
    <mat-form-field
      floatPlaceholder="auto"
      class="pl-0 pull-left col-6 col-md-4"
    >
      <input
        matInput
        [maskito]="dollarMask"
        placeholder="Dollars to allocate"
        name="alloc"
        [formControl]="dollarControl"
        (input)="updateValues($event, 'dollar', dollarControl)"
      />
      <mat-error>This field is required</mat-error>
    </mat-form-field>
    <mat-form-field
      floatPlaceholder="auto"
      class="pl-0 pull-left col-6 col-md-4"
    >
      <input
        matInput
        [maskito]="percentMask"
        placeholder="Percentage to allocate"
        name="allocPerc"
        [formControl]="percControl"
        (input)="updateValues($event, 'perc', percControl)"
      />
      <mat-error>This field is required</mat-error>
    </mat-form-field>
  </form>
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <button
    mat-raised-button
    color="accent"
    (click)="save()"
    *ngIf="!informationOnly"
    >Add Fund</button
  >
  <button
    mat-raised-button
    color="accent"
    (click)="getPdf()"
    *ngIf="informationOnly"
    >Download Fact Sheet</button
  >
</mat-dialog-actions>
