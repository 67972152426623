import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BookManagementService } from '../book-management.service';

@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.scss'],
})
export class PolicyDetailsComponent implements OnInit, OnDestroy {
  @Input() policyId;

  constructor(private bmSrvc: BookManagementService) {}

  loading = false;
  policyDetails = null;
  unsubscribe: Subject<any> = new Subject();
  policies = [];

  ngOnInit(): void {
    this.getPolicyDetails();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getPolicyDetails() {
    this.loading = true;
    this.bmSrvc.getPolicy(this.policyId, 'ProcessPolicy').subscribe(resp => {
      this.policyDetails = resp['policyDetails'];
      this.policies = resp['Policies'];
      this.loading = false;
    });
  }

  getRowWidth(tableCount) {
    return `tables${tableCount}`;
  }

  expand(event) {
    const el = this.closestByClass(event.target, 'policy');
    el.classList.toggle('expanded');
  }

  closestByClass(el, clazz) {
    while (!el.classList.contains(clazz)) {
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  }

  launchToAW() {
    console.log('Launch to AW');
  }
}
