<div class="page-container product-shelf-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12">Product Shelf</h1>
  @if (gridView) {
    @if (!hideGrid) {
      <div class="mb-3 grid-view-row">
        <h5 class="w-100">Grid View:</h5>
        <mat-button-toggle-group
          [(ngModel)]="gridView"
          (change)="changeView($event)"
        >
          <mat-button-toggle value="product">Product</mat-button-toggle>
          <mat-button-toggle value="income">Income Riders</mat-button-toggle>
          <mat-button-toggle value="death"
            >Death Benefit Riders</mat-button-toggle
          >
          @if (rights && rights.includes('AnnuityGrid')) {
            <mat-button-toggle value="fa">FA Rates</mat-button-toggle>
          }
          @if (rights && rights.includes('FIAGrid')) {
            <mat-button-toggle value="fia">FIA Rates</mat-button-toggle>
          }
          @if (rights && rights.includes('ShowVIAFunds')) {
            <mat-button-toggle value="rila">RILA Rates</mat-button-toggle>
          }
        </mat-button-toggle-group>
      </div>
    }

    @if (!rateViews.includes(gridView)) {
      @if (!loading && !hideGrid) {
        <app-grid-filters
          class="col-12"
          (filtersUpdated)="updateFilters($event)"
          [filters]="externalFilters"
          [reset]="resetFilters"
          [preexistingFilters]="filter"
          (clearSingleFilter)="clearSingleFilter($event)"
        ></app-grid-filters>
      }
      <ng-container *hasPermission="'UIExplanations'">
        <div class="all-types-notification" *ngIf="!hideGrid">
          <span
            class="default-types"
            [ngClass]="{ 'roll-in': !showFilterMessage }"
            *ngIf="!showFilterMessage; else no_message"
          >
            All annuity types are visible by default.
          </span>
          <span
            class="click-information"
            [ngClass]="{ 'roll-in': showFilterMessage }"
          >
            Click the <mat-icon class="clear-indicator">close</mat-icon> to
            filter by all annuity types
          </span>
        </div>
      </ng-container>
      @if (!hideGrid) {
        <div class="row mb-3 mx-1">
          <button
            mat-button
            color="primary"
            class="mr-0 ml-2"
            (click)="compare()"
            [disabled]="compareList.length < 2"
          >
            <mat-icon class="mr-2">compare_arrows</mat-icon>Compare Products
          </button>
          <app-grid-column-filter
            [columns]="gridColumns"
            (columnsUpdated)="externalColumnsUpdated($event)"
          ></app-grid-column-filter>
          @if (gridView === 'income') {
            <button
              mat-button
              color="primary"
              class="mr-0 ml-2"
              (click)="calculateIncome()"
            >
              <mat-icon class="mr-2">payments</mat-icon
              >{{ incomeCalcButtonLabel }}
            </button>
          }
          <button
            mat-button
            color="primary"
            class="ml-auto mr-0"
            (click)="reset(false)"
          >
            <mat-icon class="mr-2">replay</mat-icon>Reset
          </button>
          @if (
            (gridView === 'product' ||
              gridView === 'income' ||
              gridView === 'death') &&
            managerUnitCount > 0
          ) {
            <button
              mat-button
              color="primary"
              class="mr-0 ml-2"
              (click)="downloadExcel(productGrid)"
            >
              <mat-icon class="mr-2">cloud_download</mat-icon>Download Excel
              File
            </button>
          }
        </div>
      }

      @if (!hideGrid) {
        <kendo-grid
          class="col-12"
          #productGrid="kendoGrid"
          [kendoGridBinding]="gridData.data"
          [skip]="state.skip"
          [pageSize]="state.take"
          [pageable]="true"
          [resizable]="true"
          [sortable]="{ allowUnsort: true, mode: 'multiple' }"
          [sort]="state.sort"
          [filterable]="'menu'"
          [columnMenu]="{ columnChooser: false }"
          [filter]="state.filter"
          filterable="menu"
          (filterChange)="filterChange($event)"
          [filter]="state.filter"
          [state]="state"
          (dataStateChange)="dataStateChange($event)"
        >
          <kendo-grid-column width="144" title="Actions">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="action-buttons-container">
                @if (rights.includes('AnnuityFactsSheet')) {
                  <button
                    mat-icon-button
                    class="fs-button"
                    (click)="dlFactsheet(dataItem)"
                    color="primary"
                    matTooltip="Download Factsheet"
                    [disabled]="
                      dataItem.annuityType &&
                      (dataItem.annuityType.toLowerCase() === 'spia' ||
                        dataItem.annuityType.toLowerCase() === 'dia')
                    "
                  >
                    <mat-icon>insert_drive_file</mat-icon>
                  </button>
                }
                @if (!hideLaunchToAnnuityBtn) {
                  <button
                    mat-icon-button
                    class="fs-button"
                    (click)="createNewCase(dataItem, true)"
                    color="primary"
                    matTooltip="Create New Case for This Annuity"
                  >
                    <mat-icon>open_in_new</mat-icon>
                  </button>
                }
                <mat-checkbox
                  matTooltip="Compare Annuity"
                  (change)="compareListUpdate($event, dataItem)"
                  [(ngModel)]="compareChecked[dataItem.uniqueCode]"
                  [ngModelOptions]="{ standalone: true }"
                >
                </mat-checkbox>
              </div>
            </ng-template>
          </kendo-grid-column>
          <ng-template ngFor [ngForOf]="gridData.headers" let-column>
            <kendo-grid-column
              [field]="column.DataField"
              [hidden]="isHidden(column.DataField)"
              [title]="column.Label"
              [sortable]="column.Sort"
              [filterable]="column.Filter"
              [groupable]="column.Group"
              [media]="column.RespVis"
              [filter]="column.DataType"
              [headerClass]="columnStyleCheck(column.DataField)"
              [class]="columnStyleCheck(column.DataField)"
              [width]="column.Width"
            >
              <ng-template
                kendoGridFilterMenuTemplate
                let-filter="filter"
                let-filterService="filterService"
              >
                <kendo-multiselect
                  style="width: 99%; margin-top: -30px"
                  [data]="filterData[column.DataField]"
                  textField="display"
                  valueField="value"
                  [valuePrimitive]="true"
                  [value]="filter | filterValues"
                  (valueChange)="
                    filterValueChange($event, column.DataField, filterService)
                  "
                  [fillMode]="'outline'"
                  placeholder="Filter By..."
                ></kendo-multiselect>
              </ng-template>
              <ng-template
                *ngIf="column.Type === 'integer'"
                kendoGridCellTemplate
                let-dataItem
              >
                {{ dataItem[column.DataField] | number: '1.0-0' }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
                *ngIf="column.Type === 'integer'"
              >
                {{ value | number: '1.0-0' }}
              </ng-template>
              <ng-template
                *ngIf="column.Type === 'commaSeparator'"
                kendoGridCellTemplate
                let-dataItem
              >
                {{ dataItem[column.DataField] | number: '1.0-2' }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
                *ngIf="column.Type === 'commaSeparator'"
              >
                {{ value | number: '1.0-2' }}
              </ng-template>
              <ng-template
                *ngIf="column.Type === 'perc'"
                kendoGridCellTemplate
                let-dataItem
              >
                {{ dataItem[column.DataField] | percent }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
                *ngIf="column.Type === 'perc'"
              >
                {{ value | percent }}
              </ng-template>
              <ng-template
                *ngIf="column.Type === 'perc2'"
                kendoGridCellTemplate
                let-dataItem
              >
                {{ dataItem[column.DataField] | percent: '1.2' }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
                *ngIf="column.Type === 'perc2'"
              >
                {{ value | percent: '1.2' }}
              </ng-template>
              <ng-template
                *ngIf="column.Type === 'curr'"
                kendoGridCellTemplate
                let-dataItem
              >
                {{ dataItem[column.DataField] | currency }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
                *ngIf="column.Type === 'curr'"
              >
                {{ value | currency }}
              </ng-template>
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                *ngIf="column.DataField === 'notes'"
              >
                <span [innerHtml]="dataItem[column.DataField]"></span>
              </ng-template>
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                *ngIf="column.Validation"
              >
                {{ findValue(dataItem[column.DataField], column.Validation) }}
              </ng-template>
            </kendo-grid-column>
          </ng-template>
          <ng-template
            kendoGridDetailTemplate
            let-dataItem
            *ngIf="gridData['subHead']"
          >
            <!--  -->
            <!-- Have to make specific tabs to force order and not have multiple rate summary sections show up and the ability to show messages for empty datasets -->
            <!-- Good code is commented out below... -->
            <!--  -->
            <mat-tab-group mat-stretch-tabs *ngIf="gridView === 'product'">
              <ng-container
                *ngFor="let tab of gridData.subHead | keyvalue: originalOrder"
              >
                <mat-tab *ngIf="tab.key === 'riders'" label="Income Riders">
                  <table
                    class="detail-table"
                    *ngIf="dataItem[tab.key] && dataItem[tab.key].length > 0"
                  >
                    <thead>
                      <ng-container *ngFor="let header of tab.value">
                        <th *ngIf="!header.Hidden">
                          {{ header.Label }}
                        </th>
                      </ng-container>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of dataItem[tab.key]">
                        <ng-container *ngFor="let header of tab.value">
                          <td *ngIf="!header.Hidden">
                            <ng-container
                              *ngIf="
                                header.DataField === 'ScoreClass';
                                else normal
                              "
                            >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'positive'
                                "
                                class="positive"
                                >check_circle</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() === 'neutral'
                                "
                                class="neutral"
                                >error</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'negative'
                                "
                                class="negative"
                                >remove_circle</mat-icon
                              >
                            </ng-container>
                            <ng-template #normal>
                              <ng-container [ngSwitch]="header.Type">
                                <ng-container *ngSwitchCase="'perc'">
                                  {{ product[header.DataField] | percent }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'perc2'">
                                  {{
                                    product[header.DataField] | percent: '1.2'
                                  }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'curr'">
                                  {{ product[header.DataField] | currency }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                  <ng-container
                                    *ngIf="header.Validation; else noValidation"
                                  >
                                    {{
                                      findValue(
                                        product[header.DataField],
                                        header.Validation
                                      )
                                    }}
                                  </ng-container>
                                  <ng-template #noValidation>
                                    {{ product[header.DataField] }}
                                  </ng-template>
                                </ng-container>
                              </ng-container>
                            </ng-template>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                  <h4
                    class="w-100 text-center my-4"
                    *ngIf="dataItem[tab.key] && dataItem[tab.key].length < 1"
                  >
                    No riders were found for this annuity based on your firm's
                    product offering
                  </h4>
                </mat-tab>
                <mat-tab
                  *ngIf="tab.key === 'deathRiders'"
                  label="Death Benefit Riders"
                >
                  <table
                    class="w-100 detail-table"
                    *ngIf="dataItem[tab.key] && dataItem[tab.key].length > 0"
                  >
                    <thead>
                      <ng-container *ngFor="let header of tab.value">
                        <th *ngIf="!header.Hidden">
                          {{ header.Label }}
                        </th>
                      </ng-container>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of dataItem[tab.key]">
                        <ng-container *ngFor="let header of tab.value">
                          <td *ngIf="!header.Hidden">
                            <ng-container
                              *ngIf="
                                header.DataField === 'ScoreClass';
                                else normal
                              "
                            >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'positive'
                                "
                                class="positive"
                                >check_circle</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() === 'neutral'
                                "
                                class="neutral"
                                >error</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'negative'
                                "
                                class="negative"
                                >remove_circle</mat-icon
                              >
                            </ng-container>
                            <ng-template #normal>
                              <ng-container [ngSwitch]="header.Type">
                                <ng-container *ngSwitchCase="'perc'">
                                  {{ product[header.DataField] | percent }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'perc2'">
                                  {{
                                    product[header.DataField] | percent: '1.2'
                                  }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'curr'">
                                  {{ product[header.DataField] | currency }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                  <ng-container
                                    *ngIf="header.Validation; else noValidation"
                                  >
                                    {{
                                      findValue(
                                        product[header.DataField],
                                        header.Validation
                                      )
                                    }}
                                  </ng-container>
                                  <ng-template #noValidation>
                                    {{ product[header.DataField] }}
                                  </ng-template>
                                </ng-container>
                              </ng-container>
                            </ng-template>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                  <h4
                    class="w-100 text-center my-4"
                    *ngIf="dataItem[tab.key] && dataItem[tab.key].length < 1"
                  >
                    No death benefit riders were found for this annuity based on
                    your firm's product offering
                  </h4>
                </mat-tab>
                <mat-tab
                  *ngIf="
                    tab.key === 'fiaRates' &&
                    dataItem.annuityType.toLowerCase() === 'fia'
                  "
                  label="Rates Summary"
                >
                  <table
                    class="w-100 detail-table"
                    *ngIf="dataItem[tab.key] && dataItem[tab.key].length > 0"
                  >
                    <thead>
                      <ng-container *ngFor="let header of tab.value">
                        <th *ngIf="!header.Hidden">
                          {{ header.Label }}
                        </th>
                      </ng-container>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of dataItem[tab.key]">
                        <ng-container *ngFor="let header of tab.value">
                          <td *ngIf="!header.Hidden">
                            <ng-container
                              *ngIf="
                                header.DataField === 'ScoreClass';
                                else normal
                              "
                            >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'positive'
                                "
                                class="positive"
                                >check_circle</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() === 'neutral'
                                "
                                class="neutral"
                                >error</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'negative'
                                "
                                class="negative"
                                >remove_circle</mat-icon
                              >
                            </ng-container>
                            <ng-template #normal>
                              <ng-container [ngSwitch]="header.Type">
                                <ng-container *ngSwitchCase="'perc'">
                                  {{ product[header.DataField] | percent }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'perc2'">
                                  {{
                                    product[header.DataField] | percent: '1.2'
                                  }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'curr'">
                                  {{ product[header.DataField] | currency }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                  <ng-container
                                    *ngIf="header.Validation; else noValidation"
                                  >
                                    {{
                                      findValue(
                                        product[header.DataField],
                                        header.Validation
                                      )
                                    }}
                                  </ng-container>
                                  <ng-template #noValidation>
                                    {{ product[header.DataField] }}
                                  </ng-template>
                                </ng-container>
                              </ng-container>
                            </ng-template>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                  <h4
                    class="w-100 text-center my-4"
                    *ngIf="dataItem[tab.key] && dataItem[tab.key].length < 1"
                  >
                    No rates were found for this annuity based on your firm's
                    product offering
                  </h4>
                </mat-tab>
                <mat-tab
                  *ngIf="
                    tab.key === 'faRates' &&
                    dataItem.annuityType.toLowerCase() === 'fa'
                  "
                  label="Rates Summary"
                >
                  <table
                    class="w-100 detail-table"
                    *ngIf="dataItem[tab.key] && dataItem[tab.key].length > 0"
                  >
                    <thead>
                      <ng-container *ngFor="let header of tab.value">
                        <th *ngIf="!header.Hidden">
                          {{ header.Label }}
                        </th>
                      </ng-container>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of dataItem[tab.key]">
                        <ng-container *ngFor="let header of tab.value">
                          <td *ngIf="!header.Hidden">
                            <ng-container
                              *ngIf="
                                header.DataField === 'ScoreClass';
                                else normal
                              "
                            >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'positive'
                                "
                                class="positive"
                                >check_circle</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() === 'neutral'
                                "
                                class="neutral"
                                >error</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'negative'
                                "
                                class="negative"
                                >remove_circle</mat-icon
                              >
                            </ng-container>
                            <ng-template #normal>
                              <ng-container [ngSwitch]="header.Type">
                                <ng-container *ngSwitchCase="'perc'">
                                  {{ product[header.DataField] | percent }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'perc2'">
                                  {{
                                    product[header.DataField] | percent: '1.2'
                                  }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'curr'">
                                  {{ product[header.DataField] | currency }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                  <ng-container
                                    *ngIf="header.Validation; else noValidation"
                                  >
                                    {{
                                      findValue(
                                        product[header.DataField],
                                        header.Validation
                                      )
                                    }}
                                  </ng-container>
                                  <ng-template #noValidation>
                                    {{ product[header.DataField] }}
                                  </ng-template>
                                </ng-container>
                              </ng-container>
                            </ng-template>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                  <h4
                    class="w-100 text-center my-4"
                    *ngIf="dataItem[tab.key] && dataItem[tab.key].length < 1"
                  >
                    No rates were found for this annuity based on your firm's
                    product offering
                  </h4>
                </mat-tab>
                <mat-tab
                  *ngIf="
                    tab.key === 'viaRates' &&
                    dataItem.annuityType.toLowerCase() === 'rila'
                  "
                  label="Rates Summary"
                >
                  <table
                    class="w-100 detail-table"
                    *ngIf="dataItem[tab.key] && dataItem[tab.key].length > 0"
                  >
                    <thead>
                      <ng-container *ngFor="let header of tab.value">
                        <th *ngIf="!header.Hidden">
                          {{ header.Label }}
                        </th>
                      </ng-container>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of dataItem[tab.key]">
                        <ng-container *ngFor="let header of tab.value">
                          <td *ngIf="!header.Hidden">
                            <ng-container
                              *ngIf="
                                header.DataField === 'ScoreClass';
                                else normal
                              "
                            >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'positive'
                                "
                                class="positive"
                                >check_circle</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() === 'neutral'
                                "
                                class="neutral"
                                >error</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'negative'
                                "
                                class="negative"
                                >remove_circle</mat-icon
                              >
                            </ng-container>
                            <ng-template #normal>
                              <ng-container [ngSwitch]="header.Type">
                                <ng-container *ngSwitchCase="'perc'">
                                  {{ product[header.DataField] | percent }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'perc2'">
                                  {{
                                    product[header.DataField] | percent: '1.2'
                                  }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'curr'">
                                  {{ product[header.DataField] | currency }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                  <ng-container
                                    *ngIf="header.Validation; else noValidation"
                                  >
                                    {{
                                      findValue(
                                        product[header.DataField],
                                        header.Validation
                                      )
                                    }}
                                  </ng-container>
                                  <ng-template #noValidation>
                                    {{ product[header.DataField] }}
                                  </ng-template>
                                </ng-container>
                              </ng-container>
                            </ng-template>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                  <h4
                    class="w-100 text-center my-4"
                    *ngIf="dataItem[tab.key] && dataItem[tab.key].length < 1"
                  >
                    No rates were found for this annuity based on your firm's
                    product offering
                  </h4>
                </mat-tab>
                <mat-tab
                  *ngIf="
                    tab.key === 'funds' &&
                    dataItem[tab.key] &&
                    dataItem[tab.key].length > 0
                  "
                  label="Investment Options"
                >
                  <table class="w-100 detail-table">
                    <thead>
                      <ng-container *ngFor="let header of tab.value">
                        <th *ngIf="!header.Hidden">
                          {{ header.Label }}
                        </th>
                      </ng-container>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of dataItem[tab.key]">
                        <ng-container *ngFor="let header of tab.value">
                          <td *ngIf="!header.Hidden">
                            <ng-container
                              *ngIf="
                                header.DataField === 'ScoreClass';
                                else normal
                              "
                            >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'positive'
                                "
                                class="positive"
                                >check_circle</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() === 'neutral'
                                "
                                class="neutral"
                                >error</mat-icon
                              >
                              <mat-icon
                                *ngIf="
                                  product.ScoreClass &&
                                  product.ScoreClass.toLowerCase() ===
                                    'negative'
                                "
                                class="negative"
                                >remove_circle</mat-icon
                              >
                            </ng-container>
                            <ng-template #normal>
                              <ng-container [ngSwitch]="header.Type">
                                <ng-container *ngSwitchCase="'perc'">
                                  {{ product[header.DataField] | percent }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'perc2'">
                                  {{
                                    product[header.DataField] | percent: '1.2'
                                  }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'curr'">
                                  {{ product[header.DataField] | currency }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                  <ng-container
                                    *ngIf="header.Validation; else noValidation"
                                  >
                                    {{
                                      findValue(
                                        product[header.DataField],
                                        header.Validation
                                      )
                                    }}
                                  </ng-container>
                                  <ng-template #noValidation>
                                    {{ product[header.DataField] }}
                                  </ng-template>
                                </ng-container>
                              </ng-container>
                            </ng-template>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </mat-tab>
              </ng-container>
              <mat-tab *ngIf="showNotes(dataItem.notes)" label="Notes">
                <ng-container
                  *ngFor="
                    let note of dataItem.notes | keyvalue;
                    let idx = index
                  "
                >
                  <h5
                    *ngIf="note.key === 'notes' && note.value.length > 0"
                    [ngClass]="{ 'mt-3': idx === 0 }"
                  >
                    Notes:
                  </h5>
                  <h5
                    *ngIf="note.key === 'firmNotes' && note.value.length > 0"
                    [ngClass]="{ 'mt-3': idx === 0 }"
                  >
                    Firm Notes:
                  </h5>
                  <p *ngIf="note.value.length > 0" [innerHTML]="note.value"></p>
                </ng-container>
              </mat-tab>
            </mat-tab-group>
            <table class="w-100 detail-table" *ngIf="gridView !== 'product'">
              <thead
                *ngFor="
                  let headers of gridData.subHead | keyvalue: originalOrder
                "
              >
                <th *ngFor="let hdr of headers.value">{{ hdr.Label }}</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let column of gridData.subHead | keyvalue: originalOrder
                  "
                >
                  <td
                    *ngFor="let clm of column.value | keyvalue: originalOrder"
                    [innerHTML]="dataItem.subInfo[0][clm.value.DataField]"
                  ></td>
                </tr>
              </tbody>
            </table>
          </ng-template>
          <ng-template
            kendoPagerTemplate
            let-totalPages="totalPages"
            let-currentPage="currentPage"
          >
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons
              [buttonCount]="buttonCount"
            ></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-page-sizes
              [pageSizes]="pageSizeOptions"
            ></kendo-pager-page-sizes>
          </ng-template>
          <kendo-grid-excel
            fileName="RightBridge-ProductShelf.xlsx"
            [fetchData]="excelData"
          ></kendo-grid-excel>
        </kendo-grid>
      }
    }

    @if (gridView === 'fia') {
      <app-fia></app-fia>
    }

    @if (gridView === 'fa') {
      <app-fa></app-fa>
    }

    @if (gridView === 'rila') {
      <app-via></app-via>
    }
  }
</div>
<ngx-loading [show]="loading"></ngx-loading>
