<div class="page-container productSearch-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12">Investment Comparison</h1>
  <div class="search-container row">
    <form class="col-12 row my-3">
      <mat-form-field class="col-12 col-md-6">
        <mat-label>Select a Program</mat-label>
        <mat-select [formControl]="programControl" (selectionChange)="updateFunds($event, 'all')" required>
          <mat-option *ngFor="let option of programList" [value]="option">{{
            option.DisplayName
            }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6 investment-select">
        <mat-label>Select an Investment</mat-label>
        <input placeholder="Select a Fund" matInput [formControl]="fundControl" [matAutocomplete]="auto" required />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFund">
          @if (dropdownLoading) {
          <mat-option disabled class="dropdownLoadingSpinner"><mat-spinner diameter="48"></mat-spinner></mat-option>
          }
          @if (dropdownNoResults) {
          <mat-option disabled>No Results</mat-option>
          }
          @if (!dropdownLoading && !dropdownNoResults) {
          <mat-option *ngFor="let option of filteredFunds" [value]="option">
            {{ option.FundID }} - {{ option.FundName }}
          </mat-option>
          }
        </mat-autocomplete>
        <mat-icon class="clear-button" (click)="clearInvestment()" *ngIf="fundControl.value">
          highlight_off
        </mat-icon>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6">
        <mat-label>Currently Owned Fund Families</mat-label>
        <mat-select multiple [formControl]="ownedControl">
          <mat-option *ngFor="let option of familyList" [value]="option">{{
            option.Name
            }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6">
        <mat-label>Alternate Count</mat-label>
        <mat-select [formControl]="alternateControl">
          <mat-option value="1">1</mat-option>
          <mat-option value="2">2</mat-option>
          <mat-option value="3">3</mat-option>
          <mat-option value="4">4</mat-option>
          <mat-option value="5">5</mat-option>
          <mat-option value="6">6</mat-option>
          <mat-option value="7">7</mat-option>
          <mat-option value="8">8</mat-option>
          <mat-option value="9">9</mat-option>
          <mat-option value="10">10</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6">
        <mat-label>Time Horizon</mat-label>
        <mat-select [formControl]="horizonControl">
          <mat-option value="1">1 Year</mat-option>
          <mat-option value="2">2 Years</mat-option>
          <mat-option value="3">3 Years</mat-option>
          <mat-option value="4">4 Years</mat-option>
          <mat-option value="5">5 Years</mat-option>
          <mat-option value="6">6 - 10 Years</mat-option>
          <mat-option value="10">10+ Years</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6">
        <mat-label>Investment Amount</mat-label>
        <input placeholder="Investment Amount" matInput [formControl]="amountControl" [maskito]="dollarMask" required />
      </mat-form-field>
    </form>
    <div class="col-12">
      <button class="pull-right" mat-raised-button color="accent" [disabled]="allowReport()"
        (click)="getPdf()"><mat-icon>insert_drive_file</mat-icon>Get Comparison Report</button>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>