<h5>Filters</h5>
@if (
  filterForm &&
  (filterForm.controls.primaryForm || filterForm.controls.secondaryForm)
) {
  <form [formGroup]="filterForm">
    @for (group of formFields | keyvalue; track group.key) {
      @if (group.key === 'primaryForm' || group.key === 'secondaryForm') {
        <div formGroupName="{{ group.key }}">
          @if (showBridgeIdSearch) {
            <mat-form-field class="mr-2 policy-review">
              <mat-label>
                @if (bridgeIdOptions?.length > 0) {
                  <span> Policy Review Opportunity </span>
                } @else {
                  <span> Loading... </span>
                }
              </mat-label>
              <mat-select
                [disabled]="
                  bridgeIdOptions?.length <= 0 ||
                  (bridgeIdOptions?.length > 0 &&
                    bridgeIdOptions[0] === 'noOptions')
                "
                (selectionChange)="setBridgeIdSearch($event)"
                [value]="bridgeId"
              >
                @for (opt of bridgeIdOptions; track opt.BridgeID) {
                  <mat-option value="{{ opt.BridgeID }}">
                    {{ opt.BridgeName }}
                  </mat-option>
                }
              </mat-select>

              @if (bridgeId) {
                <button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  color="warn"
                  matTooltip="Clear Filter"
                  (click)="setBridgeIdSearch($event, true)"
                >
                  <mat-icon>close</mat-icon>
                </button>
              }
            </mat-form-field>
          }

          @for (field of group.value; track field.varName) {
            <app-grid-filter-fields
              [filterFields]="group.value"
              [groupKey]="group.key"
              [filterForm]="filterForm"
              [field]="field"
              (textChange)="textChange($event, field)"
              (dateChange)="dateChange($event, field)"
              (relatedDateChange)="relatedDateChange($event, field)"
              (selectionChange)="selectionChange($event, field)"
              (clearFilter)="clearFilter($event, field, group.key)"
            ></app-grid-filter-fields>
          }

          @if (group.key === 'primaryForm' && additionalFilters.length > 0) {
            <button
              mat-stroked-button
              class="search-button"
              [matMenuTriggerFor]="moreFiltersMenu"
            >
              <mat-icon class="mr-2">filter_alt</mat-icon> More filters
            </button>

            <mat-menu
              #moreFiltersMenu="matMenu"
              direction="down"
              yPosition="below"
            >
              @for (filter of additionalFilters; track filter.varName) {
                <button
                  mat-menu-item
                  class="mb-2"
                  (click)="$event.stopPropagation()"
                >
                  <div class="additional-filter-label">
                    {{ filter.label }}
                  </div>
                  <app-grid-filter-fields
                    [filterFields]="group.value"
                    [groupKey]="group.key"
                    [filterForm]="filterForm"
                    [field]="filter"
                    (textChange)="textChange($event, filter)"
                    (dateChange)="dateChange($event, filter)"
                    (selectionChange)="selectionChange($event, filter)"
                    (clearFilter)="clearFilter($event, filter, group.key)"
                  ></app-grid-filter-fields>
                </button>
              }
            </mat-menu>
          }

          @if (showFilterManager) {
            <app-manage-filters
              [grid]="gridName"
              [currentFormFields]="formFields"
              [currentForm]="filterForm"
              [currentFilters]="currentFilters"
              (selectedTemplate)="applySavedFilters($event)"
            ></app-manage-filters>
          }

          @if (group.key === 'primaryForm' && manualSearch) {
            <button
              mat-flat-button
              color="primary"
              class="search-button"
              (click)="triggerManualSearch()"
            >
              <mat-icon class="mr-2">search</mat-icon>
              {{ searchButtonText || 'Search Results' }}
            </button>
          }
        </div>
      }
    }
  </form>
}

<!-- {{filterForm | json}} -->
