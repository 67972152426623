<div class="products-container">
  @for (grp of assessmentTypes; track grp) {
  @if (grp === 'validate') {
  <h2>Selected Product(s)</h2>
  } @else if (grp === 'rollover') {
  <h2>Source of Funds</h2>
  } @else if (grp === 'select' && caseAssesmentType !== 2) {
  <h2> Products Considered</h2>
  } @else if (grp === 'select' && caseAssesmentType === 2) {
  <h2 class="w-100" [ngClass]="{
          'group-collapsed':
            !altsExpanded && grp === 'select' && caseAssesmentType === 2
        }">
    Alternative Products
    <button mat-button color="primary" class="pull-right expand-button" (click)="toggleAlternatives()">
      @if (!altsExpanded) {
      <span>
        <mat-icon>add</mat-icon>
        View Alternatives
      </span>
      } @else {
      <span>
        <mat-icon>remove</mat-icon>
        Hide Alternatives
      </span>
      }
    </button>
  </h2>
  }
  <ul class="products col-12 mb-5" [ngClass]="{
        'group-collapsed':
          !altsExpanded && grp === 'select' && caseAssesmentType === 2
      }">
    @for (x of products; track x.DisplayName) {
    @if (x.assessmenttype === grp) {
    <li class="product col-12 {{ x.ScoreClass }} {{
              x.DisplayName === 'Data Entry Error'
                ? 'expanded'
                : x.expandedClass
            }}">
      <div class="col-12 row">
        <div class="content col-12" [ngClass]="{
                  'no-extra-left-padding': !rights.includes(
                    'RelevanceBridgeIcon'
                  )
                }">
          <div class="row align-items-center">
            @if (rights.includes('RelevanceBridgeIcon')) {
            <span class="score-icon {{ x.ScoreClass }}">
              @switch (x.ScoreClass) {
              @case ('positive') {
              <mat-icon> check_circle </mat-icon>
              }
              @case ('neutral') {
              <mat-icon>error</mat-icon>
              }
              @case ('negative') {
              <mat-icon> remove_circle </mat-icon>
              }
              }
            </span>
            }
            @if (x.BridgeType === 'product' && !lockedCase) {
            <button mat-button color="primary" class="mr-4 select-button" (click)="selectToggle($event, x)">
              @switch (x.assessmenttype.toLowerCase()) {
              @case ('validate') {
              Deselect
              }
              @case ('select') {
              Select
              }
              }
            </button>
            }
            <h4>
              {{ x.DisplayName }}
              @if (!rights.includes('RelevanceHideBridgeScore')) {
              <span> | {{ x.score / 10 }} </span>
              }
            </h4>
          </div>
          <div class="row align-items-center flex-nowrap">
            @if (x.Amount) {
            <div class="amount-container">
              <span>
                <span class="amount-title sub">Amount to Invest</span>
                <span class="amount-display">
                  {{ x.Amount | currency }}
                  @if (x.BridgeType === 'product' && !lockedCase) {
                  <button mat-icon-button color="primary" (click)="editAmount($event, x)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  }
                </span>
              </span>
            </div>
            }
            @if (
            rights.includes('BridgeNotes') &&
            !moduleNotes.include &&
            x.vars.CurrentBridgeNote &&
            (x.vars.CurrentBridgeNote.Required === 2 ||
            (x.vars.CurrentBridgeNote.Required === 3 &&
            x.negativeReason))
            ) {
            <div class="notes-notification" [ngClass]="{ valid: x.notesValid }">
              @if (x.notesValid) {
              <mat-icon>thumb_up</mat-icon>Required Notes Entered for
              This Item
              } @else {
              <mat-icon>error</mat-icon>Notes Required for This Item
              }
            </div>
            }
            <div class="expand-button">
              <button mat-icon-button (click)="expand($event)">
                <span class="collapsed-content">
                  <mat-icon>expand_circle_down</mat-icon>
                </span>
                <span class="expanded-content">
                  <mat-icon>expand_circle_down</mat-icon>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      @if (x.PlanGrid) {
      <div class="plan-grid-table col-11">
        <table class="plan-grid">
          <thead>
            <tr>
              @for (h of x.PlanGrid.Header; track h) {
              <th>{{ h }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (t of x.PlanGrid.Data; track t) {
            <tr>
              @for (z of t; track z) {
              <td>{{ z }}</td>
              }
            </tr>
            }
          </tbody>
        </table>
      </div>
      }

      @if (x.SubItemChart) {
      <app-sub-item-chart [data]="x.SubItemChart" [chartTitle]="'Brokerage Subtypes'"
        [chartStyle]="'minimal'"></app-sub-item-chart>
      }
      @if (x.BridgeDetailChart) {
      <app-sub-item-chart [data]="x.BridgeDetailChart" [chartTitle]="x.BridgeDetailChart.Label"
        [chartStyle]="'minimal'"></app-sub-item-chart>
      }

      <div class="details col-12 custom-tabs">
        <!-- Need to Fix this left align conditional if we get more tabs than the grid -->
        <mat-tab-group mat-stretch-tabs [ngClass]="{
                  'left-align':
                    (!x.grids || x.grids.length <= 0) &&
                    (!x.bridgeCharts ||
                      (x.bridgeCharts && !x.bridgeCharts.Bridges))
                }">
          <mat-tab label="Reasons">
            @if (x.ComplianceChart) {
            <app-sub-item-chart [data]="x.ComplianceChart" [chartTitle]="'Compliance Information'"></app-sub-item-chart>
            }
            <app-reason-text class="col-6" [data]="x.trueRules"></app-reason-text>
            @if (
            rights.includes('BridgeNotes') &&
            x.vars.CurrentBridgeNote &&
            !moduleNotes.include
            ) {
            <app-notes-entry class="w-100 mt-2" [bridgeVal]="x.trueRules"
              (noteEntered)="handleNotesValidation($event, x)" [notesData]="{
                        app: 'pp',
                        profile: profile,
                        bridgeId: x.vars.CurrentBridgeNote.NoteBridgeID,
                        note: x.vars.CurrentBridgeNote.NoteText,
                        prompt: x.vars.CurrentBridgeNote.Prompt,
                        required:
                          x.vars.CurrentBridgeNote.Required === 2 ||
                          (x.vars.CurrentBridgeNote.Required === 3 &&
                            x.negativeReason)
                      }"></app-notes-entry>
            }
          </mat-tab>
          @if (x.grids && x.grids.length > 0) {
          <mat-tab label="Product Summary">
            @for (gr of x.grids; track gr) {
            <div class="grid-table w-100">
              <div class="table-header w-100">{{ gr.title }}</div>

              @for (tb of gr.rows; track tb) {
              <table [ngClass]="{
                              w33: gr.rows.length === 3,
                              w50: gr.rows.length === 2,
                              w100: gr.rows.length === 1
                            }">
                @for (rw of tb.table; track rw.value) {
                <tr>
                  <td [innerHTML]="rw.label"></td>
                  <td [innerHTML]="rw.value"></td>
                </tr>
                }
              </table>
              }
            </div>
            }
          </mat-tab>
          }
          @if (x.bridgeCharts && x.bridgeCharts.Bridges) {
          <mat-tab label="{{ x.bridgeCharts.Label }}">
            @for (
            chartGroup of x.bridgeCharts.Bridges;
            track chartGroup
            ) {
            @if (chartGroup) {
            <div class="chart-container clearfix mb-5">
              <h4 class="chart-header">{{ chartGroup.header }}</h4>
              @for (
              chrt of chartGroup.charts;
              track chrt;
              let idx = $index
              ) {
              <div class="chart pull-left w-50 px-4">
                <highcharts-chart [Highcharts]="Highcharts" [options]="chrt.Data"
                  class="w-90 mx-auto"></highcharts-chart>
                @if (chrt.Legend) {
                <div class="chart-legend" [ngClass]="{
                                    'col-12': chrt.LegendPosition === 'bottom',
                                    'col-6 ml-auto':
                                      chrt.LegendPosition === 'right'
                                  }">
                  @if (chrt.Legend.header) {
                  <table>
                    <thead>
                      <tr>
                        @for (
                        th of chrt.Legend.header;
                        track th
                        ) {
                        @if (th.toLowerCase() !== 'color') {
                        <th>
                          {{ th }}
                        </th>
                        }
                        }
                      </tr>
                    </thead>
                    <tbody>
                      @for (
                      tr of chrt.Legend.data;
                      track tr
                      ) {
                      <tr>
                        @for (
                        td of chrt.Legend.header;
                        track td;
                        let i = $index
                        ) {
                        @if (
                        td.toLowerCase() !== 'color'
                        ) {
                        <td>
                          @if (
                          td.toLowerCase() !==
                          'color' &&
                          chrt.Legend.header[0].toLowerCase() ===
                          'color' &&
                          i === 1
                          ) {
                          <span [style.background]="
                                                        tr.Color
                                                      " class="indicator"></span>
                          }
                          {{ tr[td] }}
                        </td>
                        }
                        }
                      </tr>
                      }
                    </tbody>
                  </table>
                  } @else {
                  @for (c of chrt.Legend; track c.value) {
                  <div>
                    <strong>{{ c.label }}</strong>
                    {{ c.value }}
                  </div>
                  }
                  }
                </div>
                }
              </div>
              @if (
              chartGroup.charts.length === 1 ||
              (chartGroup.charts.length > 1 && idx === 1)
              ) {
              <div class="explanation-text" [ngClass]="{
                                  'pull-left w-50':
                                    chartGroup.charts.length === 1,
                                  'w-100': chartGroup.charts.length > 1
                                }">
                @if (chartGroup.ShortDescription) {
                <p class="mx-auto mt-3">
                  {{ chartGroup.ShortDescription }}
                </p>
                }
                @if (chartGroup.reasons) {
                <ul class="criteria-list mt-3">
                  @for (
                  r of chartGroup.reasons;
                  track r.headline
                  ) {
                  <li class="mt-2">
                    <span [innerHTML]="r.headline"></span>
                    @if (
                    !rights.includes(
                    'RelevanceHideReasonScores'
                    )
                    ) {
                    <span> | {{ r.score / 10 }} </span>
                    }
                  </li>
                  }
                </ul>
                }
                @if (chartGroup.dynamicContent) {
                <p class="mx-auto mt-3">
                  {{ chartGroup.dynamicContent }}
                </p>
                }
              </div>
              }
              }
            </div>
            }
            }
          </mat-tab>
          }
        </mat-tab-group>
      </div>
    </li>
    }
    }
  </ul>
  }

  @if (moduleNotes.include && !loading) {
  <app-notes-entry class="w-100 mt-2" [moduleNotes]="true" (noteEntered)="handleNotesValidation($event)" [notesData]="{
        app: 'pp',
        profile: profile,
        note: moduleNotes.note,
        prompt: moduleNotes.prompt,
        required: moduleNotes.required
      }" [moduleBridges]="products"></app-notes-entry>
  }
</div>

@if (loading) {
<ngx-loading></ngx-loading>
}