<div class="page-container search-container container">
  <!-- <div class="w-15 float-left pt-5">
    <div class="utility-nav-container vertical">
      <button
      mat-button
      (click)="nav('dashboard')"
      [ngClass]="{ active: current === 'dash' }"
      >
      <mat-icon>dashboard</mat-icon>
        <span class="text-container">Dashboard</span>
      </button>
      
      <button
      mat-button
      (click)="nav('se/search')"
      [ngClass]="{ active: current === 'search' }"
      >
      <mat-icon>search</mat-icon>
      <span class="text-container">Find Opportunities</span>
    </button>
  </div>
</div> -->
  <div class="row w-85">
    <breadcrumb [crumb]="'se-opportunities'"></breadcrumb>
    <h1 class="header col-12">Find Opportunities</h1>
    <div class="filter-container w-100">
      <div
        class="row justify-content-between search-tile-group"
        *ngIf="!hideTiles"
      >
        <div class="col-6 col-md-4 search-tile">
          <div class="inner" (click)="setSearchType('suggestion')">
            <h4><mat-icon>storage</mat-icon>By Suggestion</h4>
            <p
              >This search finds clients and prospects for a specific
              suggestion(s).</p
            >
          </div>
        </div>
        <div class="col-6 col-md-4 search-tile">
          <div class="inner" (click)="setSearchType('timeSensitive')">
            <h4><mat-icon>timer</mat-icon>Time Sensitive</h4>
            <p
              >Suggestions that are driven by deadlines and may be lost if not
              contacted.</p
            >
          </div>
        </div>
        <div class="col-6 col-md-4 search-tile">
          <div class="inner" (click)="setSearchType('highScore')">
            <h4><mat-icon>assessment</mat-icon>High Score</h4>
            <p>Find suggestions that have the highest relevance score.</p>
          </div>
        </div>
        <div class="col-6 col-md-4 search-tile">
          <div class="inner" (click)="setSearchType('callList')">
            <h4><mat-icon>contacts</mat-icon>Call List</h4>
            <p
              >Find the clients and prospects you have added to your call
              list.</p
            >
          </div>
        </div>
        <div class="col-6 col-md-4 search-tile">
          <div class="inner" (click)="setSearchType('callBack')">
            <h4> <mat-icon>phone_callback</mat-icon>Call Back Date </h4>
            <p
              >Search by follow-up date. Use a start and end date to filter the
              list for a given day, week or month.</p
            >
          </div>
        </div>
        <div class="col-6 col-md-4 search-tile">
          <div class="inner" (click)="setSearchType('opp')">
            <h4
              ><mat-icon>perm_contact_calendar</mat-icon>Best Opportunities</h4
            >
            <p
              >Use this search to find the clients and prospects with the most
              high scoring suggestions.</p
            >
          </div>
        </div>
        <div class="col-6 col-md-4 search-tile">
          <div class="inner" (click)="setSearchType('lastAction')">
            <h4><mat-icon>event</mat-icon>Last Action</h4>
            <p
              >Retrieve clients and prospects for a given action such as: I like
              it or Follow-up.</p
            >
          </div>
        </div>
        <div class="col-6 col-md-4 search-tile">
          <div class="inner" (click)="setSearchType('name')">
            <h4><mat-icon>account_circle</mat-icon>Name</h4>
            <p
              >Look up a client or prospect by first and last name. You can use
              partial names.</p
            >
          </div>
        </div>
        <div class="col-6 col-md-4 search-tile">
          <div class="inner" (click)="setSearchType('demographics')">
            <h4> <mat-icon>public</mat-icon>Demographics </h4>
            <p
              >Look up a client or prospect by first and last name. You can use
              partial names.</p
            >
          </div>
        </div>
      </div>
      <div class="search-button-types w-100 row" *ngIf="hideTiles">
        <mat-button-toggle-group [(ngModel)]="searchType">
          <mat-button-toggle
            value="suggestion"
            (click)="setSearchType('suggestion')"
          >
            <mat-icon>storage</mat-icon>By Suggestion
          </mat-button-toggle>
          <mat-button-toggle
            value="timeSensitive"
            (click)="setSearchType('timeSensitive')"
          >
            <mat-icon>timer</mat-icon>Time Sensitive
          </mat-button-toggle>
          <mat-button-toggle
            value="highScore"
            (click)="setSearchType('highScore')"
          >
            <mat-icon>assessment</mat-icon>High Score
          </mat-button-toggle>
          <mat-button-toggle
            value="callList"
            (click)="setSearchType('callList')"
          >
            <mat-icon>contacts</mat-icon>Call List
          </mat-button-toggle>
          <mat-button-toggle
            value="callBack"
            (click)="setSearchType('callBack')"
          >
            <mat-icon>phone_callback</mat-icon>Call Back Date
          </mat-button-toggle>
          <mat-button-toggle value="opp" (click)="setSearchType('opp')">
            <mat-icon>perm_contact_calendar</mat-icon>Best Opportunities
          </mat-button-toggle>
          <mat-button-toggle
            value="lastAction"
            (click)="setSearchType('lastAction')"
          >
            <mat-icon>event</mat-icon>Last Action
          </mat-button-toggle>
          <mat-button-toggle value="name" (click)="setSearchType('name')">
            <mat-icon>account_circle</mat-icon>Name
          </mat-button-toggle>
          <mat-button-toggle
            value="demographics"
            (click)="setSearchType('demographics')"
          >
            <mat-icon>public</mat-icon>Demographics
          </mat-button-toggle>
        </mat-button-toggle-group>
        <div class="w-100 hierarchy">
          <p *ngIf="selectedUnit || selectedUser || selectedRepCode">
            <strong>Searching as: </strong>
            <ng-container
              *ngIf="selectedUnit && !selectedUser && !selectedRepCode"
              class="search-as"
              >Unit: {{ selectedUnit.name }}
            </ng-container>
            <ng-container
              *ngIf="selectedUser && !selectedRepCode"
              class="search-as"
              >User: {{ selectedUser.name }}</ng-container
            >
            <ng-container *ngIf="selectedRepCode" class="search-as"
              >Rep Code: {{ selectedRepCode.name }}</ng-container
            >
          </p>
          <button mat-button (click)="setHierarchy()" *ngIf="chooseHierarchy">
            <mat-icon>search</mat-icon>Change Search Hierarchy
          </button>
          <button
            mat-button
            (click)="clearHierarchy()"
            *ngIf="selectedUnit || selectedUser || selectedRepCode"
            class="clear"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <button
            mat-button
            (click)="setHierarchy()"
            *ngIf="selectedUnit || selectedUser || selectedRepCode"
          >
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="ogData && hideTiles">
      <p *ngIf="searchType === 'demographics' && searchModel">
        <span><strong>Demographics search based on </strong></span>
        <span *ngIf="searchModel.postalCode"
          >Postal Code: {{ searchModel.postalCode }}</span
        >
        <span *ngIf="searchModel.city">City: {{ searchModel.city }}</span>
        <span *ngIf="searchModel.ageFrom || searchModel.ageTo"
          >Age range: {{ searchModel.ageFrom }} to {{ searchModel.ageTo }}</span
        >
        <span *ngIf="searchModel.incomeFrom || searchModel.incomeTo"
          >Income range: {{ searchModel.incomeFrom }} to
          {{ searchModel.incomeTo }}</span
        >
        <span *ngIf="searchModel.assetsFrom || searchModel.assetsTo"
          >Assets range: {{ searchModel.assetsFrom }} to
          {{ searchModel.assetsTo }}</span
        >
        <span *ngIf="searchModel.productsFrom || searchModel.productsTo"
          >Products range: {{ searchModel.productsFrom }} to
          {{ searchModel.productsTo }}</span
        >
        <span *ngIf="searchModel.profileFrom || searchModel.profileTo"
          >Client Profile range: {{ searchModel.profileFrom }} to
          {{ searchModel.profileTo }}</span
        >
        <span *ngIf="searchModel.source"
          >Tax Year: {{ searchModel.source }}</span
        >
        <span
          *ngIf="
            searchModel.marketCategory &&
            (searchModel.marketCategory.youngConsumers ||
              searchModel.marketCategory.preRetirees ||
              searchModel.marketCategory.retirees ||
              searchModel.marketCategory.smallBusiness ||
              searchModel.marketCategory.professionals ||
              searchModel.marketCategory.others)
          "
          >Market Category
          <span *ngIf="searchModel.marketCategory.youngConsumers"
            >{{ searchModel.marketCategory.youngConsumers }},</span
          >
          <span *ngIf="searchModel.marketCategory.preRetirees"
            >{{ searchModel.marketCategory.preRetirees }},</span
          >
          <span *ngIf="searchModel.marketCategory.retirees"
            >{{ searchModel.marketCategory.retirees }},</span
          >
          <span *ngIf="searchModel.marketCategory.smallBusiness"
            >{{ searchModel.marketCategory.smallBusiness }},</span
          >
          <span *ngIf="searchModel.marketCategory.professionals"
            >{{ searchModel.marketCategory.professionals }},</span
          >
          <span *ngIf="searchModel.marketCategory.others">{{
            searchModel.marketCategory.others
          }}</span>
        </span>
      </p>
      <kendo-grid
        class="col-12 search-grid"
        #resultsGrid="kendoGrid"
        [kendoGridBinding]="ogData.data"
        [pageSize]="pageSize"
        [pageable]="pageableData"
        [sortable]="true"
        [filterable]="'menu'"
        [columnMenu]="true"
        [filter]="filter"
        [selectable]="{ mode: 'single' }"
        (selectionChange)="viewProfile($event)"
        (pageChange)="pageChange($event)"
      >
        <ng-template kendoGridToolbarTemplate>
          <button
            class="export"
            [ngClass]="{ disabled: !selectedProfiles.length }"
            color="primary"
            kendoGridExcelCommand
            [disabled]="!selectedProfiles.length"
          >
            <mat-icon>list_alt</mat-icon>Export to Excel
          </button>
          <!-- <button
            type="button"
            class="export"
            color="primary"
            kendoGridPDFCommand
          >
            <mat-icon>insert_drive_file</mat-icon>Export to PDF
          </button> -->
          <button
            mat-button
            class="clear-search"
            *ngIf="ogData && searchType"
            (click)="clearSearch()"
          >
            <mat-icon>clear</mat-icon>Clear Search
          </button>

          <div class="bulk">
            <mat-form-field>
              <mat-select
                placeholder="Perform Bulk Action"
                [(ngModel)]="bulkAction"
                name="bulkAction"
              >
                <mat-option value="2300">I Like it</mat-option>
                <mat-option value="2200">No Thanks</mat-option>
                <mat-option value="addToList">Add to Call List</mat-option>
                <mat-option value="removeFromList"
                  >Remove From Call List</mat-option
                >
                <mat-option value="clientReport"
                  >Download Client Report</mat-option
                >
                <mat-option value="excel">Expanded Data Excel</mat-option>
              </mat-select>
            </mat-form-field>
            <button
              mat-button
              (click)="executeBulk()"
              [disabled]="!selectedProfiles.length"
              ><mat-icon>play_circle_filled</mat-icon>Execute</button
            >
          </div>
        </ng-template>
        <kendo-grid-column
          [columnMenu]="false"
          [sortable]="false"
          [filterable]="false"
          [groupable]="false"
          [class]="'check-center'"
          [width]="50"
        >
          <ng-template kendoGridHeaderTemplate>
            <mat-checkbox
              (change)="selectAll($event)"
              color="primary"
              [(ngModel)]="allSelected"
            ></mat-checkbox>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <mat-checkbox
              class="k-grid-ignore-click"
              (change)="selectRow($event, dataItem)"
              color="primary"
              [(ngModel)]="dataItem.slctd"
            ></mat-checkbox>
          </ng-template>
        </kendo-grid-column>
        <!-- Call List, Prospect and Follow up flag. -->
        <kendo-grid-column
          [columnMenu]="false"
          [sortable]="false"
          [filterable]="false"
          [groupable]="false"
          class="indicators"
          [width]="92"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <mat-icon
              class="status-indicator"
              [ngClass]="{
                active:
                  dataItem.IsInCallList === '1' ||
                  dataItem.IsInCallList === 'True'
              }"
              matTooltip="In Call List"
              >contacts</mat-icon
            >
            <mat-icon
              class="status-indicator"
              [ngClass]="{
                active:
                  dataItem.IsProspect === '1' || dataItem.IsProspect === 'True'
              }"
              matTooltip="Is Prospect"
              >person_outline</mat-icon
            >
            <mat-icon
              class="status-indicator"
              [ngClass]="{
                active:
                  dataItem.followupflag === '1' ||
                  dataItem.followupflag === 'True'
              }"
              matTooltip="Follow Up"
              >update</mat-icon
            >
          </ng-template>
        </kendo-grid-column>
        <ng-template ngFor [ngForOf]="gridColumns" let-column>
          <kendo-grid-column
            [field]="column.DataField"
            [hidden]="isHidden(column.DataField)"
            [title]="column.Label"
            [sortable]="column.Sort"
            [filterable]="column.Filter"
            [media]="column.RespVis"
          ></kendo-grid-column>
        </ng-template>
        <!-- <kendo-grid-pdf
          fileName="Products.pdf"
          [allPages]="true"
          paperSize="letter"
          [repeatHeaders]="true"
          [landscape]="true"
        >
          <kendo-grid-pdf-margin
            top="2cm"
            left="1cm"
            right="1cm"
            bottom="2cm"
          ></kendo-grid-pdf-margin>
          <ng-template
            kendoGridPDFTemplate
            let-pageNum="pageNum"
            let-totalPages="totalPages"
          >
            <div class="page-template">
              <div class="header">
                <div style="float: right"
                  >Page {{ pageNum }} of {{ totalPages }}</div
                >
                Multi-page grid with automatic page breaking
              </div>
              <div class="footer"> Page {{ pageNum }} of {{ totalPages }} </div>
            </div>
          </ng-template>
        </kendo-grid-pdf> -->
        <kendo-grid-excel
          fileName="Opportunities.xlsx"
          [fetchData]="exportExcelData"
        ></kendo-grid-excel>
      </kendo-grid>
    </ng-container>
    <!-- <ng-template #no_data>
    <h3>Choose a Search Above to Begin</h3>
  </ng-template> -->
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
