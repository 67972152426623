<div mat-dialog-header>
  <h1>{{ data.name }}</h1>
  <mat-toolbar color="primary">
    <!-- <button mat-button>
        <mat-icon>view_list</mat-icon>Show More Options
      </button> -->
    <span
      >Amount to distribute:
      {{ adjustedAmount | currency: 'USD' : 'symbol-narrow' : '0.2-2' }}</span
    >
    <span class="method">
      <label>Allocation Method:</label>
      <mat-button-toggle-group
        #group="matButtonToggleGroup"
        [(ngModel)]="allocMethod"
      >
        <mat-button-toggle class="select-button" value="dollar"
          >$</mat-button-toggle
        >
        <mat-button-toggle class="select-button" value="percent"
          >%</mat-button-toggle
        >
      </mat-button-toggle-group>
    </span>
    <mat-slide-toggle
      class="pull-right dist-toggle"
      color="accent"
      (ngModelChange)="checkDist($event)"
      [(ngModel)]="distEven"
      [disabled]="modalState === 'showCompare'"
    >
      Distribute funds evenly
    </mat-slide-toggle>
  </mat-toolbar>
</div>
<mat-dialog-content #scroll class="dialog-container">
  <div class="inner row">
    <!-- <div class="inner row" [@slideSelect]="modalState"> -->
    <div class="row funds-container" [hidden]="modalState === 'showCompare'">
      <form #fundForm="ngForm">
        <mat-accordion class="funds col-12">
          <ng-container
            *ngFor="let x of funds; let i = index; trackBy: identify"
          >
            <mat-expansion-panel
              class="fund w-100"
              *ngIf="!x.hidden || (x.hidden && showAll)"
            >
              <mat-expansion-panel-header
                class="row"
                [collapsedHeight]="'124px'"
                [expandedHeight]="'124px'"
              >
                <div class="col-2 py-3">
                  <mat-button-toggle
                    class="select-button"
                    [checked]="model.selected[x.fundId]"
                    (click)="
                      selectFund($event, {
                        name: x.name,
                        fundId: x.fundId,
                        amount: 0,
                        symbol: x.symbol,
                        assetClass: x.assetClass,
                        fundFamilyID: x.currFund.FundFamilyID,
                        hasContributionLimit: x.currFund.HasContributionLimit,
                        sectorLimit: x.currFund.HasContributionLimit
                          ? x.currFund.ContributionLimit
                          : null
                      });
                      $event.stopPropagation()
                    "
                  >
                    <mat-icon>check_circle</mat-icon> Select
                  </mat-button-toggle>
                  <mat-checkbox
                    (click)="
                      compare($event, x.fundId); $event.stopPropagation()
                    "
                    [(ngModel)]="model.compared['compare-' + x.fundId]"
                    [name]="'compare-' + x.fundId"
                    >Compare</mat-checkbox
                  >
                </div>
                <div class="col-7 pl-0 product-name-container">
                  <h5>
                    {{ x.name }}
                    <span
                      class="sector-limit w-100"
                      *ngIf="x.hasContributionLimit"
                      ><mat-icon>warning</mat-icon> Based on previous
                      allocations and the calculated limit, total allocation to
                      this fund type is
                      {{
                        x.currFund.ContributionLimit
                          | currency: 'USD' : 'symbol-narrow' : '0.2-2'
                      }}</span
                    >
                    <span class="sector-limit w-100" *ngIf="x.multiAssetClass"
                      ><mat-icon>warning</mat-icon> Multi-Asset class funds are
                      not editable. Return to the fund search to add additional
                      amounts.</span
                    >
                  </h5>
                </div>
                <div class="col-3 pl-0 amount">
                  <mat-form-field
                    floatPlaceholder="auto"
                    class="col-12"
                    [hidden]="allocMethod === 'dollar'"
                  >
                    <input
                      matInput
                      [maskito]="percentMask"
                      type="text"
                      placeholder="Percentage to assign"
                      (click)="$event.stopPropagation()"
                      (keyup)="updateValues($event, x.fundId, 'percent')"
                      [name]="'iw-percentAmount-' + x.fundId"
                      [(ngModel)]="model.percentAmount[x.fundId]"
                      [disabled]="
                        !model.selected[x.fundId] || x.multiAssetClass
                      "
                    />
                    <mat-error
                      *ngIf="
                        'form.controls.iw-amount-' +
                          x.fundId +
                          '.errors.notEqual' && !distEven
                      "
                      >Amounts do not equal the total allocated to this asset
                      class</mat-error
                    >
                    <mat-error
                      *ngIf="
                        'form.controls.iw-amount-' +
                          x.fundId +
                          '.errors.overLimit' && x.currFund.HasContributionLimit
                      "
                      >This amount is higher than allowed for this
                      fund</mat-error
                    >
                  </mat-form-field>
                  <mat-form-field
                    floatPlaceholder="auto"
                    class="col-12"
                    [hidden]="allocMethod === 'percent'"
                  >
                    <input
                      matInput
                      [maskito]="dollarMask"
                      type="text"
                      placeholder="Dollar amount to assign"
                      (click)="$event.stopPropagation()"
                      (keyup)="updateValues($event, x.fundId, 'dollar')"
                      [name]="'iw-amount-' + x.fundId"
                      [(ngModel)]="model.amount[x.fundId]"
                      [disabled]="
                        !model.selected[x.fundId] || x.multiAssetClass
                      "
                    />
                    <mat-error
                      *ngIf="
                        'form.controls.iw-amount-' +
                          x.fundId +
                          '.errors.notEqual' && !distEven
                      "
                      >Amounts do not equal the total allocated to this asset
                      class</mat-error
                    >
                    <mat-error
                      *ngIf="
                        'form.controls.iw-amount-' +
                          x.fundId +
                          '.errors.overLimit' && x.currFund.HasContributionLimit
                      "
                      >This amount is higher than allowed for this
                      fund</mat-error
                    >
                  </mat-form-field>
                </div>
              </mat-expansion-panel-header>
              <div class="details-container row">
                <p>{{ x.currFund.InvestmentStrategy }}</p>
                <h5 class="col-11 mx-auto">Average Annual Returns</h5>
                <table class="details col-11 mx-auto">
                  <thead>
                    <tr>
                      <th>{{ x.name }}</th>
                      <!-- <th>YTD</th> -->
                      <th>1 Mo</th>
                      <th>1 Yr</th>
                      <th>3 Yr</th>
                      <th>5 Yr</th>
                      <th>10 Yr</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Growth of $10,000</td>
                      <!-- <td></td> -->
                      <td>{{
                        x.currFund['10kGrowthAfter1M'] === '($999)'
                          ? 'N/A'
                          : x.currFund['10kGrowthAfter1M'].toLocaleString()
                      }}</td>
                      <td>{{
                        x.currFund['10kGrowthAfter1Y'] === '($999)'
                          ? 'N/A'
                          : x.currFund['10kGrowthAfter1Y'].toLocaleString()
                      }}</td>
                      <td>{{
                        x.currFund['10kGrowthAfter3Y'] === '($999)'
                          ? 'N/A'
                          : x.currFund['10kGrowthAfter3Y'].toLocaleString()
                      }}</td>
                      <td>{{
                        x.currFund['10kGrowthAfter5Y'] === '($999)'
                          ? 'N/A'
                          : x.currFund['10kGrowthAfter5Y'].toLocaleString()
                      }}</td>
                      <td>{{
                        x.currFund['10kGrowthAfter10Y'] === '($999)'
                          ? 'N/A'
                          : x.currFund['10kGrowthAfter10Y'].toLocaleString()
                      }}</td>
                    </tr>
                    <tr>
                      <td>Total Return %</td>
                      <!-- <td>{{x.currFund.TenYearInvestorReturn}}</td> -->
                      <td>{{ x.currFund.TrailingReturnM1 }}</td>
                      <td>{{ x.currFund.TrailingReturnY1 }}</td>
                      <td>{{ x.currFund.TrailingReturnY3 }}</td>
                      <td>{{ x.currFund.TrailingReturnY5 }}</td>
                      <td>{{ x.currFund.TrailingReturnY10 }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-6 small text-left msCat"
                  >Category: {{ x.currFund?.RBCategory }}</div
                >
                <div class="col-6 small text-right asofdate"
                  >Data as of {{ x.currFund?.DataAsOfDate }}</div
                >
                <h5 class="col-11 mx-auto">Reasons for this Fund</h5>
                <app-reason-text
                  class="col-11 mx-auto px-0"
                  [data]="x.reasons"
                ></app-reason-text>
                <div class="w-100 small pt-10 text-right">
                  Market Data by Refinitiv
                </div>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </form>
    </div>
    <div class="row compare-container" [hidden]="modalState === 'showSelect'">
      <kendo-grid [data]="gridData" width="100%">
        <kendo-grid-column field="metric" title=""></kendo-grid-column>
        <kendo-grid-column
          *ngFor="let column of columns"
          field="{{ column.id }}"
          title="{{ column.name }}"
        ></kendo-grid-column>
      </kendo-grid>
      <div class="w-100 small pt-10 text-right"> Market Data by Refinitiv </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="row buttons w-100">
  <button
    class="leftButton"
    *ngIf="modalState === 'showSelect'"
    mat-raised-button
    color="primary"
    (click)="viewComparison()"
    [disabled]="compareList.length < 2"
  >
    <mat-icon>compare_arrows</mat-icon> Compare Funds
  </button>
  <button
    class="leftButton"
    *ngIf="modalState === 'showCompare'"
    mat-raised-button
    color="primary"
    (click)="viewComparison()"
  >
    <mat-icon>arrow_back</mat-icon> Back to Selection
  </button>
  <!-- <button [disabled]="limit && funds.length < limit || modalState == 'showCompare'" class="leftButton showMore" mat-raised-button color="primary" (click)="showAll = !showAll">
    <span *ngIf="!showAll">
      <mat-icon>add_box</mat-icon>Show All Funds
    </span>
    <span *ngIf="showAll">
      <mat-icon>indeterminate_check_box</mat-icon>Show Less Funds
    </span>
  </button> -->

  <button
    mat-raised-button
    class="leftButton showMore"
    color="primary"
    (click)="showMore()"
    [disabled]="(limit && funds.length < limit) || modalState === 'showCompare'"
  >
    <mat-icon>{{ this.showButtonIcon }}</mat-icon> {{ this.showButtonLabel }}
  </button>
  <button
    mat-raised-button
    (click)="dialogRef.close('Cancel')"
    [disabled]="modalState === 'showCompare'"
    >Cancel</button
  >
  <button
    mat-raised-button
    color="accent"
    (click)="save()"
    [disabled]="modalState === 'showCompare'"
    >Save</button
  >
</mat-dialog-actions>
