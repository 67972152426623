import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseManagementService } from '../../../case-management/case-management.service';
import { PostTradeService } from '../../post-trade.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Platform } from '@angular/cdk/platform';
import { saveAs } from '@progress/kendo-file-saver';
import { EnvironmentService } from '../../../services/environment.service';
import { uniqBy } from 'lodash';

@Component({
  selector: 'app-trade-attachments-dialog',
  templateUrl: './trade-attachments-dialog.component.html',
  styleUrls: ['./trade-attachments-dialog.component.scss'],
})
export class TradeAttachmentsDialogComponent implements OnInit {
  baseUrl: string;
  environment;
  note = '';
  fileName;
  fileNameDisplay;
  file;
  fileType;
  caseFileCols = [
    {
      varName: 'CreateDate',
      label: 'Date',
    },
    {
      varName: 'NoteText',
      label: 'Note',
    },
    {
      varName: 'CreatedByUserId',
      label: 'Reviewer ID',
    },
    {
      varName: 'Name',
      label: 'Attachments',
    },
  ];
  relatedCases = [];
  tradeAttachments;
  caseType;
  caseTitle;

  constructor(
    public cms: CaseManagementService,
    public ptSvc: PostTradeService,
    public dialogRef: MatDialogRef<TradeAttachmentsDialogComponent>,
    private snacky: MatSnackBar,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    if (this.data.bulk) {
      if (this.data.profileType === 'trade') {
        this.caseType = 'TradeBulk';
        this.caseTitle = this.data.tradeId;
      } else if (this.data.profileType === 'account') {
        this.caseType = 'AccountGroup';
        this.caseTitle = this.data.AccountID;
      }
    } else {
      if (this.data.profileType === 'trade') {
        this.caseType = 'Trade';
        this.caseTitle = this.data.tradeId;
      } else if (this.data.profileType === 'account') {
        this.caseType = 'Account';
        this.caseTitle = this.data.AccountID;
      }
    }

    if (!this.data.bulk && this.data.tradeId) {
      this.cms
        .getRelatedCases(this.data.tradeId, [this.caseType])
        .subscribe(resp => {
          if (resp['length'] > 0) {
            const cases = Object.keys(resp);
            const caseIds = [];

            cases.map(idx => {
              this.relatedCases.push(resp[idx]);
              caseIds.push(resp[idx].CRID);
            });

            this.cms
              .getCaseNotesAndFiles(JSON.stringify(caseIds))
              .subscribe(filesResp => {
                if (filesResp['length'] > 0) {
                  const formattedAttachments = [];
                  for (const index in filesResp) {
                    const relatedFile = formattedAttachments.find(
                      formattedAttachment =>
                        formattedAttachment.CreateDate ===
                        filesResp[index]['CreateDate']
                    );
                    if (!relatedFile) {
                      formattedAttachments.push(filesResp[index]);
                    } else {
                      const mergedFile = Object.assign(
                        relatedFile,
                        filesResp[index]
                      );
                      formattedAttachments.push(mergedFile);
                    }
                  }

                  this.tradeAttachments = uniqBy(
                    formattedAttachments,
                    'CreateDate'
                  );
                }
              });
          }
        });
    }
  }

  onFileSelected(event) {
    this.file = event.target.files[0];

    if (this.file) {
      this.fileName = this.file.name;
    }
  }

  clearFile() {
    this.fileName = null;
    this.fileNameDisplay = null;
    this.file = null;
    this.fileType = null;
  }

  save() {
    if (this.data.bulk) {
      this.executeBulkAction();
    } else {
      const caseData = {
        CaseManagement: {
          Type: this.caseType,
          Title: this.data.tradeId,
        },
        CaseToProfile: {
          ProfileID: [this.data.tradeId],
        },
        Client_Rep: {
          RepCode: [this.data.tradeDetail.RepCode],
        },
      };

      if (this.relatedCases.length === 0) {
        this.cms
          .createCaseManagementRecord(null, caseData)
          .subscribe(createCaseResp => {
            if (createCaseResp['result']) {
              this.saveNotesAndAttachments(createCaseResp['result']);
            }
          });
      } else {
        this.relatedCases.map(relatedCase => {
          this.saveNotesAndAttachments(relatedCase.CRID);
        });
      }
    }
  }

  executeBulkAction() {
    const formData = new FormData();
    let configurationObj;

    if (this.data.profileType === 'trade') {
      configurationObj = {
        TradeID: this.data.bulkActionIds,
        CreateCase: true,
        CaseManagement: {
          Type: this.caseType,
          Title: this.caseTitle,
        },
      };
      if (this.data.bulkAction === 'approve') {
        configurationObj['ApprovalStatus'] = 'APP';
      } else if (this.data.bulkAction === 'reopen') {
        configurationObj['ApprovalStatus'] = 'REO';
      }
    } else if (this.data.profileType === 'account') {
      configurationObj = {
        AccountID: this.data.bulkActionIds,
        CreateCase: true,
        CaseManagement: {
          Type: this.caseType,
          Title: this.caseTitle,
        },
      };
      if (this.data.bulkAction === 'approve') {
        configurationObj['ReviewStatus'] = 'REV';
      } else if (this.data.bulkAction === 'reopen') {
        configurationObj['ReviewStatus'] = 'REO';
      }
    }

    formData.append('Configuration', JSON.stringify(configurationObj));

    if (this.note !== '' && this.note !== null) {
      formData.append(
        'Note',
        JSON.stringify({
          NoteText: this.note,
          NoteTypeId: 2,
        })
      );
    }

    if (this.data.profileType === 'trade') {
      this.updateTrade(formData);
    } else if (this.data.profileType === 'account') {
      this.updateAccount(formData);
    }
  }

  updateTrade(formData) {
    this.ptSvc.updateTrade(formData).subscribe(resp => {
      if (this.fileName && resp['crid']) {
        this.saveNotesAndAttachments(resp['crid']);
      } else {
        this.dialogRef.close();
        this.dialogRef = null;
      }
    });
  }

  updateAccount(formData) {
    this.ptSvc.updateAccount(formData).subscribe(resp => {
      if (this.fileName && resp['crid']) {
        this.saveNotesAndAttachments(resp['crid']);
      } else {
        this.dialogRef.close();
        this.dialogRef = null;
      }
    });
  }

  saveNotesAndAttachments(caseId) {
    if (this.note !== '' && this.note !== null && !this.fileName) {
      const note = {
        CaseRecordId: caseId,
        NoteText: this.note,
        NoteTypeId: 2,
      };

      this.cms.addCaseRecordNote(note).subscribe(
        () => {
          this.note = '';
          this.openSnacky('Note saved');
          this.dialogRef.close();
          this.dialogRef = null;
        },
        error => {
          this.snacky.open(`Error: ${error}`);
        }
      );
    } else if (this.fileName && (this.note === '' || this.note === null)) {
      const formData = new FormData();
      formData.append('File1', this.file);
      this.fileName = '';

      this.cms.saveCaseRecordFile(caseId, formData).subscribe(
        () => {
          this.openSnacky('File uploaded.');
          this.dialogRef.close();
          this.dialogRef = null;
        },
        error => {
          this.snacky.open(`Error: ${error}`);
        }
      );
    } else {
      const formData = new FormData();
      formData.append('File1', this.file);
      this.fileName = '';
      formData.append(
        'Note',
        JSON.stringify({
          CaseRecordId: caseId,
          NoteText: this.note,
          NoteTypeId: 2,
        })
      );
      this.cms.saveCaseRecordFile(caseId, formData).subscribe(
        () => {
          this.openSnacky('File uploaded & note saved.');
          this.dialogRef.close();
          this.dialogRef = null;
        },
        error => {
          this.snacky.open(`Error: ${error}`);
        }
      );
    }
  }

  openSnacky(message) {
    this.snacky.open(message, 'Close', {
      duration: 3000,
    });
  }

  downloadAttachment(fileDetails) {
    this.cms.getCaseRecordFile(fileDetails.id).subscribe(resp => {
      const filename = `${fileDetails.Name}`;
      const iosSafari =
        this.pltfrm.IOS ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(resp);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari,
        });
      };
    });
  }

  cancel() {
    this.dialogRef.close('cancel');
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }
}
