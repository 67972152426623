import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { SessionStorageService } from '../services/session-storage.service';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../services/environment.service';
import { ErrorReportingService } from '../services/error-reporting.service';

@Injectable()
export class AdminService {
  private environment;
  unit;
  private org: string;

  constructor(
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService,
    private http: HttpClient,
    private envSvc: EnvironmentService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();
    this.org = this.environment.org;
  }

  setUnit(unit) {
    this.unit = unit;
  }

  searchUnits(search?): Observable<any> {
    let url = `${this.rbs.baseUrl}/AppAdmin/SearchUnits?`;
    let paramAdded = false;
    Object.keys(search).forEach(el => {
      switch (el) {
        case 'name':
          if (search.name) {
            url += `${
              paramAdded
                ? `&unitName=${search.name}`
                : `unitName=${search.name}`
            }`;
            paramAdded = true;
          }
          break;
        case 'id':
          if (search.id) {
            url += `${
              paramAdded ? `&unitID=${search.id}` : `unitID=${search.id}`
            }`;
            paramAdded = true;
          }
          break;
        case 'type':
          if (search.type) {
            url += `${
              paramAdded
                ? `&unitType=${search.type}`
                : `unitType=${search.type}`
            }`;
            paramAdded = true;
          }
          break;
        case 'parentUnit':
          if (search.parentUnit) {
            url += `${
              paramAdded
                ? `&parentID=${search.parentUnit}`
                : `parentID=${search.parentUnit}`
            }`;
            paramAdded = true;
          }
          break;
      }
    });

    return this.rbs.getRequest(url);
  }

  getUnitList(search?): Observable<any> {
    let url = this.rbs.baseUrl + '/Hierarchy/Unit?searchString=';

    if (search) {
      url += search;
    } else {
      url += '%25';
    }

    return this.rbs.getRequest(url);
  }

  saveUnit(unit, name, type, parent) {
    const parentId = parent ? parent : '';

    return this.rbs.postRequest(
      `${this.rbs.baseUrl}/AppAdmin/Unit?unitID=${unit}&unitName=${name}&unitType=${type}&parentID=${parentId}`
    );
  }

  saveUnitRole(role, unit, note = null) {
    if (note) {
      return this.rbs.postRequest(
        `${this.rbs.baseUrl}/AppAdmin/Unit/Role?unitID=${unit}&role=${role}&note=${note}`
      );
    } else {
      return this.rbs.postRequest(
        `${this.rbs.baseUrl}/AppAdmin/Unit/Role?unitID=${unit}&role=${role}`
      );
    }
  }

  removeUnitRole(role, unit, note = null) {
    if (note) {
      return this.rbs.deleteRequest(
        `${this.rbs.baseUrl}/AppAdmin/Unit/Role?unitID=${unit}&role=${role}&note=${note}`
      );
    } else {
      return this.rbs.deleteRequest(
        `${this.rbs.baseUrl}/AppAdmin/Unit/Role?unitID=${unit}&role=${role}`
      );
    }
  }

  getUnitDetails(unit: string): Observable<any> {
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/AppAdmin/Unit?unitID=${unit}`
    );
  }

  getUnitProfile(unit: string, app: string, ui?: string): Observable<any> {
    this.rbs.setApp(app);
    this.setUnit(unit);
    const uiType = ui ? ui : `${app}-unit`;
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/AppAdmin/UnitQuestionnaire?unitID=${unit}&type=${uiType}`
    );
  }

  getUnitProfileReport(
    unit: string,
    app: string,
    type: string
  ): Observable<any> {
    this.rbs.setApp(app);
    return this.rbs.postRequestForFile(
      `${this.rbs.baseUrl}/Reports/UnitProfile/${unit}?reportType=${type}`
    );
  }

  getUnitActiveUsersReport(unit: string, type: string): Observable<any> {
    const url = `${this.rbs.baseUrl}/Reports/UnitActiveUsers/${unit}?reportType=${type}`;
    const sessId = this.ss.get('session');
    const headers = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org)
      .set('Accept', `application/${type}`);
    if (type === 'json') {
      return this.http
        .post(
          url,
          { responseType: type },
          { headers: headers, observe: 'response', responseType: 'blob' }
        )
        .pipe(
          map(res => {
            return res.body;
          }),
          catchError((error: any) => {
            return this.errSvc.handleError(error);
          })
        );
    }
    if (type === 'csv') {
      return this.http
        .post(
          url,
          { responseType: type },
          { headers: headers, observe: 'response', responseType: 'blob' }
        )
        .pipe(
          map(res => {
            return res.body;
          }),
          catchError((error: any) => {
            return this.errSvc.handleError(error);
          })
        );
    }
  }

  saveUnitProfile(unit: string, app: string, data: object): Observable<any> {
    this.rbs.setApp(app);
    return this.rbs.patchRequest(
      `${this.rbs.baseUrl}/UnitProfile/${unit}`,
      data
    );
  }

  getReportTypes(app: string) {
    this.rbs.setApp(app);
    return this.rbs.getRequest(`${this.rbs.baseUrl}/Reports/UnitReportTypes`);
  }

  getGridData(gridId, unit, solution, filter?) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('responseType', 'application/json');
    let url = `${this.rbs.baseUrl}/UnitProfile/Grid?unitID=${unit}&gridID=${gridId}`;

    if (filter) {
      url += `&filterKey=${filter}`;
    }

    return this.rbs.getRequest(url, reqHeaders);
  }

  saveGridData(gridId, unit, app, data) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org);
    return this.rbs.patchRequest(
      `${this.rbs.baseUrl}/UnitProfile/Grid?unitID=${unit}&gridID=${gridId}`,
      data,
      reqHeaders
    );
  }

  getCheckSolutions() {
    return this.rbs.getRequest(`${this.rbs.baseUrl}/Admin/CheckSolutions`);
  }

  getProfileHistory(profile, solution) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('responseType', 'application/csv');
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/Profile/ChangeHistoryConsolidated/${profile}`,
      reqHeaders
    );
  }

  getBridgeHistory(profile, solution) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('responseType', 'application/csv');
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/Widget/BridgeHistory/${profile}`,
      reqHeaders
    );
  }

  getProductChangeHistory(data, solution) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('responseType', 'application/csv');
    return this.rbs.postRequest(
      `${this.rbs.baseUrl}/Widget/ProductChangeID`,
      data,
      reqHeaders
    );
  }

  getUnitProfileHistory(unitId, solution) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('responseType', 'application/json');
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/UnitProfile/History/${unitId}`,
      reqHeaders
    );
  }

  rollbackUnitProfile(id, solution) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('responseType', 'application/json');
    return this.rbs.patchRequest(
      `${this.rbs.baseUrl}/UnitProfile/Rollback/${id}`,
      null,
      reqHeaders
    );
  }

  downloadHistoricUnitProfile(id, solution, type) {
    const sessId = this.ss.get('session');
    let reqHeaders;

    if (type == 'json') {
      reqHeaders = new HttpHeaders()
        .set('X-RB-S', sessId)
        .set('X-RB-I', solution)
        .set('X-RB-O', this.org)
        .set('accept', 'application/json');
    } else {
      reqHeaders = new HttpHeaders()
        .set('X-RB-S', sessId)
        .set('X-RB-I', solution)
        .set('X-RB-O', this.org)
        .set('accept', 'application/xml');
    }

    const url = `${this.rbs.baseUrl}/UnitProfile/HistoryProfile/${id}`;

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          // this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  downloadUnitProfile(app, unit) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/xml')
      .set('Accept', 'application/xml');
    const url = `${this.rbs.baseUrl}/UnitProfile/${unit}?extended=false`;

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  uploadUnitProfile(app, unit, profile) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org);

    return this.rbs.postRequest(
      `${this.rbs.baseUrl}/UnitProfile/${unit}`,
      profile
    );
  }

  postKBPackage(data: FormData, note: string, solution: string) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/Admin/SaveKB?note=${note}`;

    return this.http
      .post(url, data, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'text',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  postUIConfig(data: FormData, note: string, solution: string) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/Admin/SaveUIConfig?note=${note}`;

    return this.http
      .post(url, data, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'text',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          // this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  postUnitProfile(
    data: FormData,
    UnitId: string,
    solution: string,
    note?: string
  ): Observable<string> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org);

    let url = `${this.rbs.baseUrl}/Admin/UnitProfile?UnitId=${UnitId}`;

    if (note) {
      url += `&note=${note}`;
    }

    return this.http
      .post(url, data, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'text',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  postMassUnitProfile(
    data: FormData,
    solution: string,
    note?: string
  ): Observable<string> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org);

    let url = `${this.rbs.baseUrl}/Admin/BulkUnitProfile`;

    if (note) {
      url += `?note=${note}`;
    }

    return this.http
      .post(url, data, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'text',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  postUserUpload(data: FormData): Observable<string> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'pp')
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/User/UploadUsers`;

    return this.http
      .post(url, data, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'text',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          // this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  downloadXMLProfile(id, solution) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('accept', 'application/xml');

    const url = `${this.rbs.baseUrl}/Profile/XML/${id}`;

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          // this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  downloadJSONProfile(id, solution) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('accept', 'application/json');

    const url = `${this.rbs.baseUrl}/Profile/Json/${id}`;

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  downloadAllUnitProfiles(solution, extended, excelFile) {
    let url;
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('accept', 'application/zip');

    if (excelFile) {
      url = `${this.rbs.baseUrl}/UnitProfile/AllUnitSettings`;
    } else {
      url = `${this.rbs.baseUrl}/UnitProfile/AllUnits?extended=${extended}&filePrefix=${this.environment.env}_`;
    }

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  getSolutionLogFiles() {
    const session = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', session)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/Admin/SolutionLogFiles`;

    return this.http
      .get(url, { headers: reqHeaders, observe: 'response' })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return of(error);
        })
      );
  }

  downloadRecentSLF(solutionID) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org)
      .set('accept', 'text/plain');

    const url = `${this.rbs.baseUrl}/Admin/SolutionLogFile?solutionName=${solutionID}`;

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  downloadGroupProfile(id, solution, group, save) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('accept', 'application/json');

    const url = `${this.rbs.baseUrl}/GroupProfile/${id}?groupName=${group}&saveProfile=${save}`;

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          // this.logout();
          return this.errSvc.handleError(error);
        })
      );
  }

  deleteLifeProduct(data, column, file) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', data.app)
      .set('X-RB-O', this.org);

    let url = `${this.rbs.baseUrl}/LifeProducts/DeleteFromSpreadsheet?table=${data.value}`;

    if (column) {
      url = url + `&matchColumns=${column}`;
    }

    return this.http
      .post(url, file, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'text',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return of(error);
        })
      );
  }

  exportLifeProducts(data) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', data.app)
      .set('X-RB-O', this.org)
      .set('accept', 'application/csv');

    const url = `${this.rbs.baseUrl}/LifeProducts/ExportSpreadsheet?table=${data.value}`;

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          // this.logout();
          return Observable.throwError(error.error || 'Server error');
        })
      );
  }

  importLifeProducts(table, data) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', table.app)
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/LifeProducts/ImportSpreadsheet?table=${table.value}`;

    return this.http
      .post(url, data, { headers: reqHeaders, observe: 'response' })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          // this.logout();
          return of(error);
        })
      );
  }

  downloadHandlebarTemplates() {
    const url = `${this.rbs.baseUrl}/Dtcc/HandlebarTemplates`;
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org)
      .set('accept', 'application/zip');

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  uploadeHandlebarTemplate(id, handlebarFile) {
    const session = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', session)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/Dtcc/HandlebarTemplates?templateID=${id}`;

    return this.http
      .patch(url, handlebarFile, { headers: reqHeaders, observe: 'response' })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return of(error);
        })
      );
  }

  uploadReportTemplate(data: string) {
    const session = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', session)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/Reports/Test`;

    return this.http
      .post(url, data, { headers: reqHeaders, observe: 'response' })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return of(error);
        })
      );
  }
  requestReportTemplate(data: string) {
    const session = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', session)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/Reports/Test`;

    return this.http
      .post(url, data, {
        headers: reqHeaders,
        responseType: 'text',
        observe: 'response',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return of(error);
        })
      );
  }
  customReport(data) {
    const session = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', session)
      .set('X-RB-I', data.app)
      .set('X-RB-O', this.org)
      .set('Accept', data.type);

    const url = `${this.rbs.baseUrl}/Reports/Custom`;

    return this.http
      .post(url, data.payload, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return of(error);
        })
      );
  }

  getUIConfigHeaders(data) {
    const session = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', session)
      .set('X-RB-I', data.implementation)
      .set('X-RB-O', this.org);

    const url = `${this.rbs.baseUrl}/Admin/UIConfigHeaders?uiconfig=${data.uiconfig}&type=${data.type}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getKBChanges() {
    const url = `${this.rbs.baseUrl}/AppAdmin/KBChanges`;
    return this.rbs.getRequest(url);
  }
  getKBSectionChanges(changeID: string) {
    const url = `${this.rbs.baseUrl}/AppAdmin/KBSectionChanges?changeID=${changeID}`;
    return this.rbs.getRequest(url);
  }
  getKBSectionChangeData(changeID: string, sectionID: string) {
    const url = `${this.rbs.baseUrl}/AppAdmin/KBSectionChangeData?changeID=${changeID}&sectionID=${sectionID}`;
    return this.rbs.getRequest(url);
  }
}
