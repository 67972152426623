import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-account-review',
  templateUrl: './account-review.component.html',
  styleUrls: ['./account-review.component.scss'],
})
export class AccountReviewComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router
  ) {}

  unsubscribe: Subject<any> = new Subject();
  accountId = '';

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        if (params.id) {
          this.accountId = params.id;
        } else {
          this.accountId = '';
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }
}
