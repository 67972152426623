import {
  map,
  switchMap,
  distinctUntilChanged,
  debounceTime,
} from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  Output,
  Input,
  EventEmitter,
} from '@angular/core';
import { UntypedFormGroup, FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UniversalSearchService } from '../services/universal-search.service';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-hierarchy-modal',
  templateUrl: './hierarchy-modal.component.html',
  styleUrls: ['./hierarchy-modal.component.scss'],
})
export class HierarchyModalComponent implements OnInit {
  // @ViewChild('userCtrl') userCtrl: FormGroup;
  // @ViewChild('unitCtrl') unitCtrl: FormGroup;
  // @ViewChild('repCtrl') repCtrl: FormGroup;
  @Input() templateInData;
  @Input() isDialog = true;
  @Output() templateOutData = new EventEmitter();

  form;
  users: any[];
  filteredUsers;
  selectedUser;
  units: any[];
  filteredUnits;
  selectedUnit;
  repCodes: any[];
  filteredRepCodes;
  selectedRepCode;

  userSearch;
  unitSearch;
  repCodeSearch;

  unitCtrl = new FormControl();
  userCtrl = new FormControl();
  repCtrl = new FormControl();
  rights;

  constructor(
    private dialogRef: MatDialogRef<HierarchyModalComponent>,
    private usServ: UniversalSearchService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ss: SessionStorageService
  ) {}

  ngOnInit() {
    this.rights = this.ss.get('rights');

    this.unitCtrl.setValue(this.data.data ? this.data.data.unit : null);
    this.userCtrl.setValue(this.data.data ? this.data.data.user : null);
    this.repCtrl.setValue(this.data.data ? this.data.data.rep : null);

    this.getUnits();
    this.getUsers();
    this.getRepCodes();
  }

  getUsers() {
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(user => {
        if (user.length > 2) {
          return this._filterUsers(user);
        }
        if (!user || user.length === 0) {
          this.selectedUser = null;
        }
        return [];
      })
    );
  }

  selectUser(ev) {
    this.selectedUser = ev.option.value;
    const outData = {
      data: {
        unit: this.selectedUnit,
        user: this.selectedUser,
        rep: this.selectedRepCode,
      },
    };
    this.templateOutData.emit(outData);
  }

  getUnits() {
    this.filteredUnits = this.unitCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(unit => {
        if (unit.length > 2) {
          return this._filterUnits(unit);
        }
        if (!unit || unit.length === 0) {
          this.selectedUnit = null;
        }
        return [];
      })
    );
  }

  selectUnit(ev) {
    this.selectedUnit = ev.option.value;
    const outData = {
      data: {
        unit: this.selectedUnit,
        user: this.selectedUser,
        rep: this.selectedRepCode,
      },
    };
    this.templateOutData.emit(outData);
  }

  getRepCodes() {
    this.filteredRepCodes = this.repCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(rep => {
        if (rep.length > 2) {
          return this._filterReps(rep);
        }
        if (!rep || rep.length === 0) {
          this.selectedRepCode = null;
        }
        return [];
      })
    );
  }

  selectRep(ev) {
    this.selectedRepCode = ev.option.value;
    const outData = {
      data: {
        unit: this.selectedUnit,
        user: this.selectedUser,
        rep: this.selectedRepCode,
      },
    };
    this.templateOutData.emit(outData);
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    const outData = {
      data: {
        unit: this.selectedUnit,
        user: this.selectedUser,
        rep: this.selectedRepCode,
      },
    };
    this.dialogRef.close(outData);
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
  }

  private _filterUnits(value: string) {
    let list = this.usServ.unitSearch(value).pipe(
      map(x => {
        return x.names;
      })
    );
    return list;
  }

  private _filterUsers(value: string) {
    const unit = this.selectedUnit || null;
    let list = this.usServ.userSearch(value, unit).pipe(
      map(x => {
        return x.names;
      })
    );
    return list;
  }

  private _filterReps(value: string) {
    const unit = this.selectedUnit || null;
    let list = this.usServ.repCodeSearch(value, unit).pipe(
      map(x => {
        return x.names;
      })
    );
    return list;
  }

  displayFn(val) {
    return val ? val.name : '';
  }

  displayReps(val) {
    return val ? `${val.repCode} ${val.name} (${val.unitName})` : '';
  }
}
