<div class="toggle-mic-container">
  <div class="mic-btn-container">
    <button
      mat-mini-fab
      color="{{ micButtonStatus }}"
      class="toggle-mic-btn"
      (click)="toggleListening($event)"
    >
      <mat-icon>mic</mat-icon>
    </button>
  </div>
</div>
