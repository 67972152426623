import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { SessionStorageService } from '../../../services/session-storage.service';
import { UnifiedFlowService } from '../../../unified-flow/unified.service';
import { ActivatedRoute } from '@angular/router';
import { Global } from '../../../models/global.model';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RightBridgeApiService } from '../../../services/right-bridge-api.service';

@Component({
  selector: 'app-form-repcode',
  styleUrls: ['form-repCode.component.scss'],
  template: `
    <label
      class="section w-100"
      [class.mat-error]="formControl.errors && formControl.touched"
      *ngIf="props.label"
      [innerHTML]="props.label"
      ><sup *ngIf="props.required">*</sup></label
    >
    <p *ngIf="props.prompt" class="explanation" [innerHTML]="props.prompt"></p>
    <mat-form-field class="w-100">
      <mat-label>{{ loading ? 'Loading Rep Codes...' : to.label }}</mat-label>
      @if (repCodeTypeAhead) {
        <input
          type="text"
          [placeholder]="props.placeholder || props.label"
          aria-label="Rep Code Search"
          matInput
          [formControl]="formControl"
          [matAutocomplete]="repAutocomplete"
          [required]="props.required"
          [formlyAttributes]="field"
          class="w-100"
        />
        <mat-autocomplete
          #repAutocomplete="matAutocomplete"
          (optionSelected)="autoCompleteRepChanged($event)"
        >
          @for (opt of repUnits; track opt) {
            <mat-option
              [value]="opt"
              matTooltip="{{ opt.name }} ({{ opt.repCode }})"
              matToolTipPosition="before"
              matTooltipShowDelay="750"
              >{{ opt.name }} ({{ opt.repCode }})</mat-option
            >
          }
        </mat-autocomplete>
      } @else {
        <mat-select
          [formControl]="formControl"
          [placeholder]="props.placeholder || props.label"
          [formlyAttributes]="field"
          class="w-100"
          [required]="props.required"
          (selectionChange)="props.change ? to.change(field, formControl) : ''"
        >
          <mat-option *ngFor="let opt of dropOptions" [value]="opt.value">{{
            opt.label
          }}</mat-option>
        </mat-select>
        <mat-icon
          *ngIf="props.help"
          class="help-icon"
          matTooltip="{{ to.help }}"
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'rb-tooltip'"
          >help</mat-icon
        >
      }
      @if (
        formControl.hasError('required') && !formControl.hasError('regRequired')
      ) {
        <mat-error>This field is required</mat-error>
      }
      @if (formControl.hasError('regRequired')) {
        <mat-error
          >This field is needed for suitability review. You can opt out below if
          the applicant refuses to provide the information.</mat-error
        >
      }
    </mat-form-field>
  `,
})
export class FormlyRepCodeComponent extends FieldType implements OnInit {
  globals: Global;
  dropOptions = [{ value: 'no_rep_codes', label: 'No Rep Codes Available' }];
  disabledDrop = false;
  appStatus = false;
  profile = '';
  loading = false;
  repCodeTypeAhead = false;
  filteredReps: Observable<string[]>;
  repUnits: {
    repCode: string;
    name: string;
    unitId: string;
    unitName: string;
    unitType: string;
  }[];

  constructor(
    private ss: SessionStorageService,
    private unfFlowSvc: UnifiedFlowService,
    private route: ActivatedRoute,
    private rbs: RightBridgeApiService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.formControl.disable();
    this.globals = this.ss.get('globals');
    this.repUnits = this.ss.get('repUnits');

    if (Array.isArray(this.formControl.value)) {
      this.formControl.setValue(this.formControl.value[0]);
    }
    this.route.params.subscribe(params => {
      this.profile = params.id ? params.id : null;
    });
    if (
      this.globals &&
      this.globals.user.managerUnitCount &&
      this.globals.user.managerUnitCount > 0
    ) {
      this.repCodeTypeAhead = true;
      this.setUpTypeAhead();
    } else {
      this.repCodeTypeAhead = false;
      if (this.profile) {
        this.unfFlowSvc.getApps(this.profile).subscribe(x => {
          x.Modules.map(module => {
            if (module.vars.ModuleStatus.FlowStatus === 'C') {
              this.appStatus = true;
            }
          });
          this.setUpDropDown();
        });
      } else {
        this.setUpDropDown();
      }
    }
  }

  setUpTypeAhead() {
    this.loading = false;
    this.formControl.enable();
    this.formControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (value && typeof value === 'string' && value.length > 0) {
          return this._filterReps(value);
        } else {
          this.repUnits = [];
          this.changeDetector.detectChanges();
        }
      });
  }

  private _filterReps(value: string) {
    this.rbs.getRepUnits(value).subscribe(res => {
      if (res && res.names.length > 0) {
        this.repUnits = res.names;
        this.changeDetector.detectChanges();
      } else if (!res || res.names.length === 0) {
        this.repUnits = [];
        this.changeDetector.detectChanges();
      }
    });
  }

  autoCompleteRepChanged(ev) {
    this.formControl.setValue(ev.option.value.repCode);
  }

  displayRepCode(opt) {
    return opt ? opt.name : '';
  }

  setUpDropDown() {
    if (!this.repUnits || this.repUnits.length === 0) {
      this.rbs.getRepUnits().subscribe(res => {
        this.repUnits = res;
        this.repCodeEval();
      });
    } else {
      this.repCodeEval();
    }
  }

  repCodeEval() {
    const rawRepCodes = this.repUnits.map(x => x['repCode']);

    if (rawRepCodes.length < 1 && this.props.rawValues) {
      this.removeLoadingOption();
      this.dropOptions = [this.props.rawValues];
      this.formControl.disable();
    } else if (
      rawRepCodes.length > 0 &&
      this.formControl.value != null &&
      !rawRepCodes.includes(this.formControl.value)
    ) {
      this.removeLoadingOption();
      this.dropOptions = this.repUnits.map(x => {
        return {
          value: x['repCode'],
          label: `${x['repName']} (${x['repCode']})`,
        };
      });
      this.formControl.disable();
    } else if (this.profile && this.appStatus) {
      this.removeLoadingOption();
      this.dropOptions = this.repUnits.map(x => {
        return {
          value: x['repCode'],
          label: `${x['repName']} (${x['repCode']})`,
        };
      });
      this.formControl.disable();
    } else if (
      (rawRepCodes.length > 0 && this.formControl.value == null) ||
      (rawRepCodes.length > 0 &&
        this.formControl.value != null &&
        rawRepCodes.includes(this.formControl.value)) ||
      !this.profile ||
      (this.profile && !this.appStatus)
    ) {
      this.dropOptions = this.repUnits.map(x => {
        return {
          value: x['repCode'],
          label: `${x['repName']} (${x['repCode']})`,
        };
      });
      this.removeLoadingOption();
    } else {
      this.removeLoadingOption();
      this.formControl.setValue('no_rep_codes');
      this.formControl.disable();
    }
  }

  removeLoadingOption() {
    const idx = this.dropOptions.findIndex(
      x => x.value === 'loading_rep_codes'
    );
    if (idx > -1) {
      this.dropOptions.splice(idx, 1);
    }
    this.formControl.setValue(this.formControl.value);
    this.loading = false;
    this.formControl.enable();
  }
}
