<div mat-dialog-header>
  <h1>{{ gridTitle }} Review Download</h1>
</div>
<div mat-dialog-content class="dialog-container">
  @if (!downloadRequested) {
    <mat-form-field>
      <mat-label>File Name</mat-label>
      <input
        matInput
        type="text"
        [placeholder]="'File Name'"
        [(ngModel)]="fileName"
      />
    </mat-form-field>
  } @else {
    <div> Your report has been requested. </div>
    <div> When it is finished generating you can view it </div>
    <div> at the downloaded reports page. </div>
  }
</div>
<div mat-dialog-actions class="row buttons">
  <button mat-button color="warn" (click)="dialogRef.close('Cancel')">
    Cancel
  </button>
  @if (!downloadRequested) {
    <button
      mat-raised-button
      [disabled]="!fileName"
      color="primary"
      (click)="downloadGridReport()"
    >
      <mat-icon>insert_drive_file</mat-icon>Generate Report
    </button>
  } @else {
    <button
      mat-raised-button
      color="primary"
      (click)="dialogRef.close('Confirm')"
    >
      Confirm
    </button>
  }
</div>
