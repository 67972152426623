import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { states } from '../../../lib/states';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UniversalSearchService } from '../../../universal-search/services/universal-search.service';
import { DateTime } from 'luxon';
import { FormControl } from '@angular/forms';
import { MaskService } from '../../../services/mask.service';
import { CaseManagementService } from '../../../case-management/case-management.service';
import { CustomFiltersService } from '../../../services/custom-filters.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-grid-filter-fields',
  templateUrl: './grid-filter-fields.component.html',
  styleUrls: ['./grid-filter-fields.component.scss'],
})
export class GridFilterFieldsComponent implements OnInit, OnDestroy {
  @Input() field;
  @Input() filterForm;
  @Input() groupKey;
  @Output() textChange = new EventEmitter();
  @Output() dateChange = new EventEmitter();
  @Output() relatedDateChange = new EventEmitter();
  @Output() selectionChange = new EventEmitter();
  @Output() clearFilter = new EventEmitter();
  @ViewChild('pickerField') pickerField;
  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  floatingRangeOptions = [
    { value: '30 days', display: 'Last 30 Days' },
    { value: '90 days', display: 'Last 90 Days' },
    { value: 'mtd', display: 'Month-to-Date' },
    { value: 'ytd', display: 'Year-to-Date' },
    { value: 'custom', display: 'Custom Range' },
  ];
  start = new FormControl<Date | null>(null);
  end = new FormControl<Date | null>(null);
  relatedStart = new FormControl<Date | null>(null);
  relatedEnd = new FormControl<Date | null>(null);
  dollarMask = this.mask.dollarMaskSpecs();
  states = states;
  unsubscribe = new Subject();

  constructor(
    private cms: CaseManagementService,
    public usServ: UniversalSearchService,
    public cstmFltrSrvc: CustomFiltersService,
    private mask: MaskService
  ) {}

  ngOnInit(): void {
    if (
      this.field.controlType === 'repCode' ||
      this.field.controlType === 'unit' ||
      this.field.controlType === 'user'
    ) {
      this.getFilteredOptions(this.field.varName);
    }

    if (this.field.controlType === 'presetRange') {
      this.cstmFltrSrvc.updatePresets$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          field => {
            if (field['customRange']) {
              this.start.setValue(
                DateTime.fromISO(field['customRange']['start'], {
                  zone: 'utc',
                })
                  .plus({ days: 1 })
                  .startOf('day')
                  .toISO()
              );
              this.end.setValue(new Date(field['customRange']['end']));
            }
          },
          error => alert(error)
        );
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  changeText(ev) {
    this.textChange.emit(ev);
  }

  changeSelection(ev) {
    this.selectionChange.emit(ev);
  }

  changeDate(ev) {
    this.dateChange.emit(ev);

    if (this.field.controlType === 'presetRange') {
      // Change date range select to custom
      this.filterForm.controls[this.groupKey].controls[
        this.field.varName
      ].setValue('custom');
    }

    if (this.field.controlType === 'relatedDate') {
      // Clear the related dates
      this.relatedStart.setValue(null);
      this.relatedEnd.setValue(null);
    }
  }

  clearDateField() {
    this.start.setValue(null);
    this.end.setValue(null);
    this.filterForm.controls[this.groupKey].controls[
      this.field.varName
    ].setValue(null);
  }

  changeRelatedDate(ev) {
    this.relatedDateChange.emit(ev);
    // Clear the related dates
    this.start.setValue(null);
    this.end.setValue(null);
  }

  resetFilter(ev) {
    this.clearFilter.emit(ev);
  }

  getFilteredOptions(varName) {
    this.filterForm.controls[this.groupKey].controls[varName].valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (this.field.controlType === 'repCode') {
          this.getRepCodeList(value);
        }
        if (this.field.controlType === 'unit') {
          this.getUnitList(value);
        }
        if (this.field.controlType === 'user') {
          this.getUserList(value);
        }
      });
  }

  getRepCodeList(searchString?: string) {
    this.usServ.repCodeSearch(searchString).subscribe(resp => {
      const newRepCodeList = resp.names.map(rep => ({
        value: rep.repCode,
        display: rep.name,
      }));
      this.field.fullField.externalFilter['options'] = newRepCodeList;
    });
  }

  getUnitList(searchString?: string) {
    this.usServ.unitSearch(searchString).subscribe(resp => {
      const newUnitList = resp.names.map(rep => ({
        value: rep.id,
        display: rep.name,
      }));
      this.field.fullField.externalFilter['options'] = newUnitList;
    });
  }

  getUserList(searchString?: string) {
    // Possible temporary thing for CaseManagement.
    // Strange behavior for these two fields without the following code.
    if (
      this.field.varName === 'AssignedTo' ||
      this.field.varName === 'SubmittedBy'
    ) {
      this.cms.getCmUserFilterList(searchString).subscribe(resp => {
        const newUsersList = resp.Users.map(rep => ({
          value: rep.UserID,
          display: rep.UserName,
        }));
        this.field.fullField.externalFilter['options'] = newUsersList;
      });
    } else {
      this.usServ.userSearch(searchString).subscribe(resp => {
        const newUsersList = resp.names.map(rep => ({
          value: rep.UserID,
          display: rep.UserName,
        }));
        this.field.fullField.externalFilter['options'] = newUsersList;
      });
    }
  }

  getDropdownOptions() {
    return this.field.fullField.externalFilter['options'] || [];
  }

  showClearButton(fieldName) {
    if (this.filterForm.controls[this.groupKey].controls[fieldName].value) {
      return true;
    }
    return false;
  }

  rangeSelection(ev) {
    console.log('>>>>>', ev);
    const { value } = ev;
    if (value === 'custom') {
      setTimeout(() => {
        this.pickerField.open();
      });
    } else if (value === 'mtd') {
      // Set the date picker with the selected times
      const calculatedStartDate = DateTime.now()
        .startOf('month')
        .toLocaleString();
      this.start.setValue(new Date(calculatedStartDate));
      this.end.setValue(new Date());
    } else if (value === 'ytd') {
      // Set the date picker with the selected times
      const calculatedStartDate = DateTime.now()
        .startOf('year')
        .toLocaleString();
      this.start.setValue(new Date(calculatedStartDate));
      this.end.setValue(new Date());
    } else {
      // Calculate time difference
      const calculateTime = value.split(' ')[0];
      const calculateUnit = value.split(' ')[1];
      const timeDiffObject = {};
      timeDiffObject[calculateUnit] = calculateTime;
      const calculatedStartDate = DateTime.now()
        .minus(timeDiffObject)
        .toLocaleString();

      // Set the date picker with the selected times
      this.start.setValue(new Date(calculatedStartDate));
      this.end.setValue(new Date());

      // Fire dateChange method to update the filters
      this.dateChange.emit({
        target: this.startDatePicker,
        targetElement: this.startDatePicker.nativeElement,
        value: new Date(calculatedStartDate),
      });
      this.dateChange.emit({
        target: this.endDatePicker,
        targetElement: this.endDatePicker.nativeElement,
        value: new Date(),
      });
    }
  }

  clearRange(ev) {
    this.clearFilter.emit(ev);
    this.start.setValue(null);
    this.end.setValue(null);

    this.dateChange.emit({
      target: this.startDatePicker,
      targetElement: this.startDatePicker.nativeElement,
      value: null,
    });
    this.dateChange.emit({
      target: this.endDatePicker,
      targetElement: this.endDatePicker.nativeElement,
      value: null,
    });
  }
}
