import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SortDescriptor,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FilterService, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CaseManagementService } from '../case-management.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { CaseManagementModalComponent } from '../case-management-modal/case-management-modal.component';
import { AdHocModalComponent } from '../ad-hoc-modal/ad-hoc-modal.component';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { AdminService } from '../../admin/admin.service';

@Component({
  selector: 'app-case-management',
  templateUrl: './case-management.component.html',
  styleUrls: ['./case-management.component.scss'],
})
export class CaseManagementComponent implements OnInit, OnDestroy {
  @ViewChild('caseManagementGrid', { static: false }) caseManagementGrid;

  constructor(
    private cms: CaseManagementService,
    private cstmFltrSrvc: CustomFiltersService,
    private ss: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private dialog: MatDialog,
    private rbs: RightBridgeApiService,
    private adminService: AdminService,
    private route: Router
  ) {}

  globals = this.ss.get('globals');

  assignedToControl = new FormControl();
  autocompleteFilters = ['AssignedTo', 'SubmittedBy'];
  currentFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  dateFilters = {};
  defaultUser = this.globals ? this.globals.user : null;
  dropdownFilters = {};
  externalFilters = [];
  filterData = {};
  filterForm: FormGroup = this.fb.group({});
  formFields = { primaryForm: [], secondaryForm: [] };
  hideGrid = true;
  loading = false;
  ogExternalFilters = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public gridColumns = [];
  public gridData;
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  public ogData;
  public pageSize = 25;
  public pageSizeOptions = [25, 50, 75, 100];
  public skip = 0;
  public sort: SortDescriptor[] = [{ field: 'DateLastUpdated', dir: 'desc' }];
  resetFilters = false;
  submitteByControl = new FormControl();
  titleControl = new FormControl();
  typeControl = new FormControl();
  unsubscribe: Subject<any> = new Subject();
  useFilteredUsers = false;
  filterOptions = {
    Type: [],
    AssignedToName: [],
    SubmittedBy: [],
  };
  state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize,
  };

  ngOnInit(): void {
    this.getGridSetup();
  }

  getGridSetup() {
    this.filterOptions = {
      Type: [],
      AssignedToName: [],
      SubmittedBy: [],
    };

    this.adminService
      .getUIConfigHeaders({
        uiconfig: 'cm-grid',
        type: 'cm-grid',
        implementation: 'cm',
      })
      .subscribe(res => {
        this.gridColumns = res['headers'];

        if (this.gridColumns && this.gridColumns.length > 0) {
          // if (
          //   this.globals &&
          //   this.globals.user.rights.includes('FilteredUserFilter')
          // ) {
          //   this.getFilteredOptions();
          //   this.autocompleteFilters.map(filter => {
          //     this.useFilteredUsers = true;
          //     const updateFilter = this.gridColumns.find(
          //       column => column.DataField === filter
          //     );
          //     if (updateFilter) {
          //       updateFilter.externalFilter.controlType = 'autocomplete';
          //       updateFilter.externalFilter.filterType = 'contains';
          //     }
          //   });
          // } else {
          this.getUserList();
          // }

          this.activatedRoute.params
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(params => {
              if (params?.id !== 'undefined') {
                this.getGridData(+params.id);
              } else {
                this.getGridData();
              }
            });
        } else {
          console.error('No grid column data returned from request.');
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getUserList(searchString?: string) {
    this.cms.getCmUserFilterList(searchString).subscribe(resp => {
      const newUsersList = resp.Users.map(user => ({
        value: user.UserID,
        display: user.UserName,
      }));
      this.autocompleteFilters.map(filter => {
        this.filterOptions[filter] = resp.Users;
        this.gridColumns.find(column => {
          return column.DataField === filter;
        }).externalFilter.options = newUsersList;
      });
    });
  }

  getGridData(defaultCRID?) {
    this.loading = true;
    const data = {
      search: { profileValues: {} },
      skip: this.state.skip,
      take: this.state.take,
      sort: {},
    };
    data.sort = {
      DateLastUpdated: 'DESC',
    };

    if (defaultCRID) {
      data.search.profileValues['CRID'] = defaultCRID;
    }

    if (this.titleControl && this.titleControl.value) {
      data.search.profileValues['Title'] = this.titleControl.value;
    }

    if (this.typeControl && this.typeControl.value) {
      data.search.profileValues['Type'] = this.typeControl.value;
    }

    if (this.filter.filters && this.filter.filters.length > 0) {
      data.search = { profileValues: data.search.profileValues };
      this.filter.filters.forEach(filter => {
        if (filter['operator'] !== 'start' && filter['operator'] !== 'end') {
          data.search.profileValues[filter['field']] = filter['value'];
        } else if (!data.search[filter['field']]) {
          data.search[filter['field']] = {};
        }
        if (filter['operator'] === 'start') {
          data.search[filter['field']]['start'] = filter['value'];
        } else if (filter['operator'] === 'end') {
          data.search[filter['field']]['end'] = filter['value'];
        }
      });
    }

    if (this.sort && this.sort.length > 0) {
      this.sort.forEach(sort => {
        if (sort['dir']) {
          data.sort[sort['field']] = sort['dir'].toUpperCase();
        } else {
          delete data.sort[sort['field']];
        }
      });
    }
    this.cms.getCaseManagementData(data).subscribe(resp => {
      const gridData = { data: resp['Data'], total: resp['total'] };
      this.state.skip = resp['skip'];
      this.ogData = gridData;
      this.gridData = gridData;
      this.buildFilterOptions();
      this.updateExternalFilterOptions(this.gridColumns);

      if (defaultCRID) {
        this.filterValueChange([defaultCRID], 'CRID', new FilterService());
        this.reviewCase({ CRID: defaultCRID });
      }

      this.buildTypeOptions(this.ogData.data);

      this.hideGrid = false;
      this.loading = false;
    });
  }

  buildTypeOptions(data) {
    const options = [];

    this.rbs.postGroupProfile('CaseType', 'cm').subscribe(z => {
      z.UnitCaseType.forEach(x => {
        options.push({ value: x.Type, display: x.FirmTypeName });
      });

      data.forEach(x => {
        if (!this.filterOptions.Type) {
          this.filterOptions['Type'] = [];
        }
        if (
          this.filterOptions['Type'] &&
          !this.filterOptions['Type'].includes(x.Type)
        ) {
          this.filterOptions['Type'].push(x.Type);
        }
      });
      const typeFilter = this.externalFilters.find(
        x => x.fullField.DataField === 'Type'
      );
      typeFilter.DataField = typeFilter.fullField.DataField;
      typeFilter.options = options;
    });
  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );
  }

  public filterValueChange(
    values: any[],
    field,
    filterService: FilterService
  ): void {
    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: 'eq',
        value,
      })),
      logic: 'or',
    });
  }

  externalColumnsUpdated(ev) {
    // This is a hack to stop the grid from scrolling to the right
    const gridContentScroll = document.querySelector('.k-grid-content');
    const oldScrollPosition = gridContentScroll.scrollLeft;

    const headerWrapContentScroll = document.querySelector(
      '.k-grid-header-wrap'
    );
    const headerWrapPosition = headerWrapContentScroll.scrollLeft;
    setTimeout(() => {
      gridContentScroll.scrollLeft = oldScrollPosition;
      headerWrapContentScroll.scrollLeft = headerWrapPosition;
    });

    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const hidden = fullList.filter(y => !ev.includes(y));

    this.updateHidden({ value: hidden });
  }

  useDefault() {
    this.filter = { logic: 'and', filters: [] };
    this.dateFilters = {};
    this.dropdownFilters = {};
    this.assignedToControl.setValue('');
    this.submitteByControl.setValue('');
    this.sort = [];
    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.getGridData();
  }

  updateHidden(ev) {
    this.hidden = [...ev.value];
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  updateFilters(ev) {
    this.resetFilters = false;
    this.skip = 0;

    this.filter = ev;

    if (this.filter.filters && this.filter.filters.length > 0) {
      this.filter.filters = this.deDupObjectArray([...this.filter.filters]);
    }
  }

  searchResults() {
    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };

    this.getGridData();
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  updateExternalFilterOptions(headers) {
    headers
      .filter(x => x.externalFilter)
      .forEach(el => {
        const entry = el.externalFilter;
        entry.varName = el.DataField;
        entry.fullField = el;

        if (
          this.externalFilters.findIndex(x => x.varName === el.DataField) === -1
        ) {
          this.externalFilters.push(entry);
        }

        if (el.Type && el.Type.toLowerCase() == 'curr') {
          entry.controlType = 'currency';
        }

        const entryIdx = this.externalFilters.findIndex(x => {
          return x.varName == el.DataField;
        });
        this.externalFilters[entryIdx].options =
          this.buildDropdownFilterOptions(el);
      });
  }

  buildDropdownFilterOptions(el) {
    let options = [];
    if (this.filterOptions[el.DataField]) {
      options = this.filterOptions[el.DataField].map(x => {
        if (typeof x === 'object') {
          return { value: x.UserID, display: x.UserName };
        } else {
          return { value: x, display: x };
        }
      });
    }
    options = this.deDupObjectArray(options);
    options = this.clearEmptyFields(options);
    options = options.sort((a, b) => (a.display > b.display ? 1 : -1));

    if (el.Validation) {
      options = options.map(x => {
        let label = el.Validation.find(z => x.value == z.value);

        label = label ? label.label : x.value;

        return { value: x.value, display: label };
      });
    }

    return options;
  }

  showClearButton(fieldName) {
    if (
      this.state.filter.filters.find(filter => filter['field'] === fieldName)
    ) {
      return true;
    }
    return false;
  }

  getDropdownOptions(fieldName) {
    return this.externalFilters.find(filter => filter.label === fieldName)
      ? this.externalFilters.find(filter => filter.label === fieldName).options
      : [];
  }

  getFilteredOptions() {
    this.assignedToControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        this.getUserList(value);
      });

    this.submitteByControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        this.getUserList(value);
      });
  }

  clearEmptyFields(data) {
    const updated = data.filter(item => {
      if (typeof item.value === 'string' && item.value.length < 1) {
        return false;
      }

      return item;
    });

    return updated;
  }

  sortChange(sort: SortDescriptor[]) {
    this.sort = sort;
    this.getGridData();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.state.skip = this.skip;
    this.state.take = event.take;
    this.getGridData();
  }

  reset() {
    this.loading = true;
    this.hideGrid = true;
    this.sort = [];
    this.filter = { logic: 'and', filters: [] };
    this.skip = 0;
    this.buildFilterOptions();
    this.externalFilters = [];

    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };

    this.resetFilters = true;

    this.getGridSetup();
  }

  reviewCase(caseData) {
    const data = caseData;
    data['defaultUserId'] = this.defaultUser.id;
    const cmDialog = this.dialog.open(CaseManagementModalComponent, {
      panelClass: 'app-case-management-modal',
      height: '90vh',
      width: '90vw',
      maxHeight: '90vh',
      maxWidth: '90vw',
      data,
      autoFocus: false,
    });

    cmDialog.afterClosed().subscribe(() => {
      this.getGridData();
    });
  }

  createAdHocCase() {
    const dialogRef = this.dialog.open(AdHocModalComponent, {
      panelClass: 'app-ad-hoc-modal',
      data: {
        useFilteredUsers: this.useFilteredUsers,
        usersList: this.gridColumns.find(
          column => column.DataField === 'AssignedTo'
        ).externalFilter.options,
        typeFilters: this.externalFilters.filter(
          filter => filter.varName === 'Type'
        )[0].options,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.CRID) {
        const cmDialog = this.dialog.open(CaseManagementModalComponent, {
          panelClass: 'app-case-management-modal',
          height: '90vh',
          width: '90vw',
          maxHeight: '90vh',
          maxWidth: '90vw',
          data: {
            CRID: result.CRID,
            defaultUserId: this.defaultUser.id,
          },
          autoFocus: false,
        });

        cmDialog.afterClosed().subscribe(() => {
          this.getGridData();
        });
      }
    });
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }
}
